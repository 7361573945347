export const GET_WITHDRAWAL_LIST = "GET_WITHDRAWAL_LIST"
export const GET_WITHDRAWAL_LIST_SUCCESS = "GET_WITHDRAWAL_LIST_SUCCESS"
export const GET_WITHDRAWAL_LIST_FAIL = "GET_WITHDRAWAL_LIST_FAIL"
export const UPDATE_WITHDRAWAL_LIST = "UPDATE_WITHDRAWAL_LIST"
export const UPDATE_WITHDRAWAL_LIST_SUCCESS = "UPDATE_WITHDRAWAL_LIST_SUCCESS"
export const UPDATE_WITHDRAWAL_LIST_FAIL = "UPDATE_WITHDRAWAL_LIST_FAIL"




