import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import QRCode from "react-qr-code";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
    WithdrawalId, WithdrawalName, PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount, Points, PostedDate, LastDate, Status
}
    from "./common-settings-col";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Form,
    Input,
    Label,
    Card,
    CardBody,
    Container,
    CardTitle,
    FormFeedback

} from "reactstrap";

import {
    getSettings as onGetSettings,
    upateSettings as onUpateSettings
} from "store/actions";

function CommonSettings() {

    //meta title
    document.title = "Common Settings | SCRATCH IT & WIN IT";

    const [appUpdateSettings, setAppUpdateSettings] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    var initialWithdrawalValues = {};
    var initialSpinValues = {};
    var initialDailyWatchAdValues = {};

    const dispatch = useDispatch();
    const { settings, isUpdateLoading } = useSelector(state => ({
        settings: state.SettingsReducer.settings,
        isUpdateLoading: state.SettingsReducer.isUpdateLoading
    }));

    useEffect(() => {
        if (isEmpty(appUpdateSettings)) {
            dispatch(onGetSettings());
        }
    }, [dispatch]);

    const removeCommaFirstAndLast = (data) => {
        return data.replace(/^,*|,*$/g, '');
    }

    const validationWithdrawal = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: initialWithdrawalValues,
        validationSchema: Yup.object({
            exchangeRate: Yup.string().required("Please enter exchange rate"),
            minRedeem: Yup.string().required("Please enter min redeem coins"),
            maxRedeem: Yup.string().required("Please enter max redeem coins"),
            processFee: Yup.string().required("Please enter transaction fee")
        }),
        validate: (values) => {
            let errors = {};

            if (values.isShow) {
                if (values.note === '') {
                    errors.note = "Please enter note."
                }
                if (values.textColor === '') {
                    errors.textColor = "Please enter text color code."
                }
                if (values.containerBgColor === '') {
                    errors.containerBgColor = "Please enter cointanier background color."
                }
                if (values.paymentNote === '') {
                    errors.paymentNote = "Please enter payment note."
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            const updateWithdrawalSettings = {
                "exchange_rate": values.exchangeRate,
                "min_redeem": values.minRedeem,
                "max_redeem": values.maxRedeem,
                "process_fee": values.processFee,
                "note": {
                    "isShow": values.isShow,
                    "note": values.note,
                    "textColor": values.textColor,
                    "containerBgColor": values.containerBgColor,
                    "payment_note": values.paymentNote
                }
            };
            // update app settings
            dispatch(onUpateSettings({
                "type": "withdrawal",
                "data": updateWithdrawalSettings
            }));

        },
    });


    const validationSpin = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: initialSpinValues,
        validationSchema: Yup.object({
            coins: Yup.string().required("Please enter coins (comma separated)"),
            spinLimit: Yup.string().required("Please enter daily spin limit"),
            spinCharge: Yup.string().required("Please enter spin charge")

        }),
        validate: (values) => {
            let errors = {};
            return errors;
        },
        onSubmit: (values) => {
            const updateSpinSettings = {
                "id": "5",
                "coins": removeCommaFirstAndLast(values.coins).split(','),
                "spin_charge": values.spinCharge,
                "spin_limit": values.spinLimit,
            };
            // update app settings
            dispatch(onUpateSettings({
                "type": "spin",
                "data": updateSpinSettings
            }));

        },
    });

    const validationDailyWatchAd = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: initialSpinValues,
        validationSchema: Yup.object({
            dailyWatchAdLimit: Yup.string().required("Please enter daily watch ads count"),
        }),
        validate: (values) => {
            let errors = {};
            return errors;
        },
        onSubmit: (values) => {
            const updateDailyWatchAdSettings = {
                "daily_watch_ad_limit": values.dailyWatchAdLimit
            }
            // update app settings
            dispatch(onUpateSettings({
                "type": "daily_watch_ad",
                "data": updateDailyWatchAdSettings
            }));

        },
    });




    useEffect(() => {
        if (!isEmpty(settings)) {
            setAppUpdateSettings(settings);
            initialWithdrawalValues = {
                note: settings.withdrawal.note.note,
                textColor: settings.withdrawal.note.textColor,
                containerBgColor: settings.withdrawal.note.containerBgColor,
                paymentNote: settings.withdrawal.note.payment_note,
                isShow: settings.withdrawal.note.isShow,
                exchangeRate: settings.withdrawal.exchange_rate,
                minRedeem: settings.withdrawal.min_redeem,
                maxRedeem: settings.withdrawal.max_redeem,
                processFee: settings.withdrawal.process_fee,
            };

            initialSpinValues = {
                coins: settings.spin.coins.toString(),
                spinLimit: settings.spin.spin_limit,
                spinCharge: settings.spin.spin_charge,
            }
            initialDailyWatchAdValues = {
                dailyWatchAdLimit: settings.daily_watch_ad.daily_watch_ad_limit,

            }
            validationWithdrawal.setValues({
                ...initialWithdrawalValues
            });
            validationSpin.setValues({
                ...initialSpinValues
            })
            validationDailyWatchAd.setValues({ ...initialDailyWatchAdValues })

        }
    }, [settings]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Settings" breadcrumbItem="Common" />
                    <Row>
                        <Col xl={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Withdrawal Settings</CardTitle>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validationWithdrawal.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Exchange Rate</Label>
                                                    <Input
                                                        name="exchangeRate"
                                                        type="text"
                                                        placeholder="Enter exchange rate"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationWithdrawal.handleChange}
                                                        onBlur={validationWithdrawal.handleBlur}
                                                        value={validationWithdrawal.values.exchangeRate || ""}
                                                        invalid={
                                                            validationWithdrawal.touched.exchangeRate && validationWithdrawal.errors.exchangeRate ? true : false
                                                        }
                                                    />
                                                    {validationWithdrawal.touched.exchangeRate && validationWithdrawal.errors.exchangeRate ? (
                                                        <FormFeedback type="invalid">{validationWithdrawal.errors.exchangeRate}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Transaction Fee</Label>
                                                    <Input
                                                        name="processFee"
                                                        type="text"
                                                        placeholder="Enter transaction fee"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationWithdrawal.handleChange}
                                                        onBlur={validationWithdrawal.handleBlur}
                                                        value={validationWithdrawal.values.processFee || ""}
                                                        invalid={
                                                            validationWithdrawal.touched.processFee && validationWithdrawal.errors.processFee ? true : false
                                                        }
                                                    />
                                                    {validationWithdrawal.touched.processFee && validationWithdrawal.errors.processFee ? (
                                                        <FormFeedback type="invalid">{validationWithdrawal.errors.processFee}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Min Redeem Coins</Label>
                                                    <Input
                                                        name="minRedeem"
                                                        type="text"


                                                        placeholder="Enter min redeem"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationWithdrawal.handleChange}
                                                        onBlur={validationWithdrawal.handleBlur}
                                                        value={validationWithdrawal.values.minRedeem || ""}
                                                        invalid={
                                                            validationWithdrawal.touched.minRedeem && validationWithdrawal.errors.minRedeem ? true : false
                                                        }
                                                    />
                                                    {validationWithdrawal.touched.minRedeem && validationWithdrawal.errors.minRedeem ? (
                                                        <FormFeedback type="invalid">{validationWithdrawal.errors.minRedeem}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Max Redeem Coins</Label>
                                                    <Input
                                                        name="maxRedeem"
                                                        type="text"
                                                        placeholder="Enter max redeem"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationWithdrawal.handleChange}
                                                        onBlur={validationWithdrawal.handleBlur}
                                                        value={validationWithdrawal.values.maxRedeem || ""}
                                                        invalid={
                                                            validationWithdrawal.touched.maxRedeem && validationWithdrawal.errors.maxRedeem ? true : false
                                                        }
                                                    />
                                                    {validationWithdrawal.touched.maxRedeem && validationWithdrawal.errors.maxRedeem ? (
                                                        <FormFeedback type="invalid">{validationWithdrawal.errors.maxRedeem}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Note</Label>
                                            <Input
                                                name="note"
                                                type="text"


                                                placeholder="Enter note"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validationWithdrawal.handleChange}
                                                onBlur={validationWithdrawal.handleBlur}
                                                value={validationWithdrawal.values.note || ""}
                                                invalid={
                                                    validationWithdrawal.touched.note && validationWithdrawal.errors.note ? true : false
                                                }
                                            />
                                            {validationWithdrawal.touched.note && validationWithdrawal.errors.note ? (
                                                <FormFeedback type="invalid">{validationWithdrawal.errors.note}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="formrow-firstname-Input">Payment Note</Label>
                                            <Input
                                                name="paymentNote"
                                                type="text"
                                                placeholder="Enter payment note"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validationWithdrawal.handleChange}
                                                onBlur={validationWithdrawal.handleBlur}
                                                value={validationWithdrawal.values.paymentNote || ""}
                                                invalid={
                                                    validationWithdrawal.touched.paymentNote && validationWithdrawal.errors.paymentNote ? true : false
                                                }
                                            />
                                            {validationWithdrawal.touched.paymentNote && validationWithdrawal.errors.paymentNote ? (
                                                <FormFeedback type="invalid">{validationWithdrawal.errors.paymentNote}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Text Color Code</Label>
                                                    <Input
                                                        name="textColor"
                                                        type="text"
                                                        placeholder="Enter text color code"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationWithdrawal.handleChange}
                                                        onBlur={validationWithdrawal.handleBlur}
                                                        value={validationWithdrawal.values.textColor || ""}
                                                        invalid={
                                                            validationWithdrawal.touched.textColor && validationWithdrawal.errors.textColor ? true : false
                                                        }
                                                    />
                                                    {validationWithdrawal.touched.textColor && validationWithdrawal.errors.textColor ? (
                                                        <FormFeedback type="invalid">{validationWithdrawal.errors.textColor}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Container Background Code</Label>
                                                    <Input
                                                        name="containerBgColor"
                                                        type="text"
                                                        placeholder="Enter background color code"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationWithdrawal.handleChange}
                                                        onBlur={validationWithdrawal.handleBlur}
                                                        value={validationWithdrawal.values.containerBgColor || ""}
                                                        invalid={
                                                            validationWithdrawal.touched.containerBgColor && validationWithdrawal.errors.containerBgColor ? true : false
                                                        }
                                                    />
                                                    {validationWithdrawal.touched.containerBgColor && validationWithdrawal.errors.containerBgColor ? (
                                                        <FormFeedback type="invalid">{validationWithdrawal.errors.containerBgColor}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">
                                            <div onClick={() => {
                                                const newCheckboxValue = !validationWithdrawal.values.isShow;
                                                validationWithdrawal.setFieldValue('isShow', newCheckboxValue);
                                            }} className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    name="forceUpdate"
                                                    checked={validationWithdrawal.values.isShow ?? false}
                                                    readOnly

                                                />
                                                <Label
                                                    className="form-check-Label"
                                                    htmlFor="formrow-customCheck"
                                                >
                                                    Show withdrawal note
                                                </Label>
                                            </div>
                                        </div>

                                        <div>
                                            <button disabled={isUpdateLoading} type="submit" className="btn btn-primary w-md">
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}>


                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Spin Settings</CardTitle>

                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validationSpin.handleSubmit();
                                        return false;
                                    }}>
                                        <div className="mb-3">
                                            <Label className="form-label">Coins(comma separated)</Label>
                                            <Input
                                                name="coins"
                                                type="text"
                                                placeholder="Enter coins"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validationSpin.handleChange}
                                                onBlur={validationSpin.handleBlur}
                                                value={validationSpin.values.coins || ""}
                                                invalid={
                                                    validationSpin.touched.coins && validationSpin.errors.coins ? true : false
                                                }
                                            />
                                            {validationSpin.touched.coins && validationSpin.errors.coins ? (
                                                <FormFeedback type="invalid">{validationSpin.errors.coins}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Spin Limit</Label>
                                                    <Input
                                                        name="spinLimit"
                                                        type="text"
                                                        placeholder="Enter daily spin limit"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationSpin.handleChange}
                                                        onBlur={validationSpin.handleBlur}
                                                        value={validationSpin.values.spinLimit || ""}
                                                        invalid={
                                                            validationSpin.touched.spinLimit && validationSpin.errors.spinLimit ? true : false
                                                        }
                                                    />
                                                    {validationSpin.touched.spinLimit && validationSpin.errors.spinLimit ? (
                                                        <FormFeedback type="invalid">{validationSpin.errors.spinLimit}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Spin Charge(Coins)</Label>
                                                    <Input
                                                        name="spinCharge"
                                                        type="text"


                                                        placeholder="Enter spin charge in coins"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validationSpin.handleChange}
                                                        onBlur={validationSpin.handleBlur}
                                                        value={validationSpin.values.spinCharge || ""}
                                                        invalid={
                                                            validationSpin.touched.spinCharge && validationSpin.errors.spinCharge ? true : false
                                                        }
                                                    />
                                                    {validationSpin.touched.spinCharge && validationSpin.errors.spinCharge ? (
                                                        <FormFeedback type="invalid">{validationSpin.errors.spinCharge}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div>
                                            <button disabled={isUpdateLoading} type="submit" className="btn btn-primary w-md">
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Daily Watch Ads Settings</CardTitle>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validationDailyWatchAd.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <div className="mb-3">
                                                <Label className="form-label">Daily Watch Ads Count</Label>
                                                <Input
                                                    name="dailyWatchAdLimit"
                                                    type="text"
                                                    placeholder="Enter daily watch ads count"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    onChange={validationDailyWatchAd.handleChange}
                                                    onBlur={validationDailyWatchAd.handleBlur}
                                                    value={validationDailyWatchAd.values.dailyWatchAdLimit || ""}
                                                    invalid={
                                                        validationDailyWatchAd.touched.dailyWatchAdLimit && validationDailyWatchAd.errors.dailyWatchAdLimit ? true : false
                                                    }
                                                />
                                                {validationDailyWatchAd.touched.dailyWatchAdLimit && validationDailyWatchAd.errors.dailyWatchAdLimit ? (
                                                    <FormFeedback type="invalid">{validationDailyWatchAd.errors.dailyWatchAdLimit}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <div className="mt-2">
                                            <button type="submit" className="btn btn-primary w-md ">
                                                Submit
                                            </button>
                                        </div>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}


export default CommonSettings;