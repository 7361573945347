import {
  GET_WITHDRAWAL_LIST,
  GET_WITHDRAWAL_LIST_FAIL,
  GET_WITHDRAWAL_LIST_SUCCESS,
  UPDATE_WITHDRAWAL_LIST,
  UPDATE_WITHDRAWAL_LIST_SUCCESS,
  UPDATE_WITHDRAWAL_LIST_FAIL


} from "./actionTypes";

export const getWithdrawalList = () => ({
  type: GET_WITHDRAWAL_LIST,
})

export const getWithdrawalListSuccess = withdrawal => ({
  type: GET_WITHDRAWAL_LIST_SUCCESS,
  payload: withdrawal,
})

export const getWithdrawalListFail = error => ({
  type: GET_WITHDRAWAL_LIST_FAIL,
  payload: error,
})

export const updateWithdrawalList = withdrawal => ({
  type: UPDATE_WITHDRAWAL_LIST,
  payload: withdrawal,
})

export const updateWithdrawalListSuccess = withdrawal => ({
  type: UPDATE_WITHDRAWAL_LIST_SUCCESS,
  payload: withdrawal,
})

export const updateWithdrawalListFail = error => ({
  type: UPDATE_WITHDRAWAL_LIST_FAIL,
  payload: error,
})

