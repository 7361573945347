export const GET_USER_LIST = "GET_USERLIST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL"
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAIL = "DELETE_USER_FAIL"




