import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"
import "../UserDetails/userDetails.scss"
import TableContainer from '../../../components/Common/TableContainer';
import { useMemo } from "react";

import { WithdrawalId, WithdrawalName, PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount, Points, PostedDate, LastDate, Status } from "./withdrawal-transaction-col";

import PropTypes from 'prop-types'

function WithdrawalTransactions(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)


  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'id',
        filterable: true,
        Cell: (cellProps) => {
          return <WithdrawalId {...cellProps} />;
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
        Cell: (cellProps) => {
          return <WithdrawalName {...cellProps} />;
        }
      },
      {
        Header: 'Payment Method',
        accessor: 'payment_method_name',
        filterable: true,
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        }
      },
      {
        Header: 'Payment txnid',
        accessor: 'payment_gateway_txnid',
        filterable: true,
        Cell: (cellProps) => {
          return <PaymentTxnId {...cellProps} />;
        }
      },
      {
        Header: 'Payment Info',
        accessor: 'ref',
        filterable: true,
        Cell: (cellProps) => {
          return <PaymentInfo {...cellProps} />;
        }
      },
      {
        Header: 'Transaction Fee',
        accessor: 'transaction_fee',
        Cell: (cellProps) => {
          return <TransactionFee {...cellProps} />;
        }
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        }
      },
      {
        Header: 'points',
        accessor: 'point',
        Cell: (cellProps) => {
          return <Points {...cellProps} />;
        }
      },
      {
        Header: 'Last Date',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return <LastDate {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={props.withdrawalTransactions}
        isGlobalFilter={true}
        customPageSize={10}
      />
    </React.Fragment>
  )
}


WithdrawalTransactions.propTypes = {
  withdrawalTransactions: PropTypes.any
}

export default WithdrawalTransactions
