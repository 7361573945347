import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Users
import UsersReducer from "./Users/reducer";

//Withdrawal
import WithdrawalReducer from "./Withdrawal/reducer";

// Authentication
import Login from "./auth/login/reducer";

//Category
import CategoryReducer from "./Category/reducer";

//Settings
import SettingsReducer from "./settings/reducer";

//Profile
import Profile from "./profile/reducer";

//Notification
import Notification from "./notification/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Dashboard,
  UsersReducer,
  Login,
  Profile,
  WithdrawalReducer,
  CategoryReducer,
  SettingsReducer,
  Profile,
  Notification
  
});

export default rootReducer;
