import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"
import "../UserDetails/userDetails.scss"

import PropTypes from 'prop-types'

function DailyCoins(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu


  return (
    <React.Fragment>
      <Row>
        {
          props.dailyCoins.map((item, i) => <Col lg="3" key={i}>
            <Card >
              <CardBody className="card-radio">
                <Row>
                  <div className="text-muted">
                    <p className="mb-1" style={{ fontWeight: 500, fontSize: 14 }}>{item.name}</p>
                    <h5 className="mb-1 text-black" style={{ fontSize: 18, fontWeight: 600 }}>{item.points}</h5>

                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>)
        }
      </Row>
    </React.Fragment>
  )
}


DailyCoins.propTypes = {
  dailyCoins: PropTypes.any,
  title: PropTypes.string
}

export default DailyCoins
