import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,GET_SETTINGS_FAIL,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
} from "./actionTypes";

export const getSettings = () => ({
  type: GET_SETTINGS,
})

export const getSettingsSuccess = settings => ({
  type: GET_SETTINGS_SUCCESS,
  payload: settings,
})

export const getSettingsFail = error => ({
  type: GET_SETTINGS_FAIL,
  payload: error,
})

export const upateSettings = settings => ({
  type: UPDATE_SETTINGS,
  payload: settings,
})

export const upateSettingsSuccess = settings => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: settings,
})

export const upateSettingsFail = error => ({
  type: UPDATE_SETTINGS_FAIL,
  payload: error,
})

