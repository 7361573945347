import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { SEND_NOTIFICATION } from "./actionTypes"
import { sendNotificationSuccess, sendNotificationError } from "./actions"

//Include Both Helper File with needed methods
import {
  postSendNotification
} from "helpers/backend_helper";


function* sendNotification({ payload: data }) {
  try {
    const response = yield call(postSendNotification, data)
    if(response.status){
      yield put(sendNotificationSuccess(response.message))
    }else{
      yield put(sendNotificationError(response.message))
    }
  } catch (error) {
    yield put(sendNotificationError(error))
  }
}
export function* watchNotification() {
  yield takeEvery(SEND_NOTIFICATION, sendNotification)
}

function* NotificationSaga() {
  yield all([fork(watchNotification)])
}

export default NotificationSaga
