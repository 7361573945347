import {
    GET_USER_LIST_FAIL,
    GET_USER_LIST_SUCCESS,
    ADD_USER_LIST_SUCCESS,
    ADD_USER_LIST_FAIL,
    GET_USER_DETAIL_SUCCESS,
    GET_USER_DETAIL_FAIL,
    GET_USER_DETAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,

    EDIT_USER_SUCCESS,
    EDIT_USER_FAIL

} from "./actionTypes";

const INIT_STATE = {
    users: [],
    error: {},
    jobApply: [],
    userDetails: {}
}

const UsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                users: action.payload,
            };
        case GET_USER_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case GET_USER_DETAIL_SUCCESS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case GET_USER_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_USER_SUCCESS:
            return {
                users: state.users.filter(
                    user => user.id.toString() !== action.payload.toString()
                ),
            };
        case DELETE_USER_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case EDIT_USER_SUCCESS:
            return {
                users: state.users.map(user =>
                    user.id.toString() === action.payload.id.toString()
                        ? { user, ...action.payload }
                        : user)
            };
        case EDIT_USER_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state
    }
}

export default UsersReducer;