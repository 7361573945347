import React from 'react';
import { Badge,Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as moment from "moment";

const CategoryId = (cell) => {
    return <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const CategoryName = (cell) => {
    return cell.value ? cell.value : "";
};

const CommomCell = (cell) => {
    return cell.value ? cell.value : "";
};

const PaymentMethod = (cell) => {
    return cell.value ? cell.value : "";
};

const PaymentTxnId = (cell) => {
    return cell.value ? cell.value : "";
};

const PaymentInfo = (cell) => {
    return JSON.parse(cell.row.values.ref).payment_id;
};

const TransactionFee = (cell) => {
    return cell.value ? cell.value : "";
};

const Amount = (cell) => {
    if(cell.row.values){
        var exchangeRate = JSON.parse(cell.row.values.ref).exchange_rate ? JSON.parse(cell.row.values.ref).exchange_rate : 0.0;
        var processFee =  JSON.parse(cell.row.values.ref).process_fee ?  JSON.parse(cell.row.values.ref).process_fee : 0.0;   
        return roundToTwo((((cell.row.values.point * exchangeRate) - processFee)));
    }
    return "";
    // return cell.row ? cell.row.values.name : "";
};

const Points = (cell) => {
    return cell.value ? cell.value : "";
};

const Type = (cell) => {
    switch (cell.value) {
        case "Full Time":
            return <Badge className="badge-soft-success">Full Time</Badge>
        case "Part Time":
            return <Badge className="badge-soft-danger">Part Time</Badge>
        case "Freelance":
            return <Badge className="badge-soft-info">Freelance</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
    };   
};

const PostedDate = (cell) => {
    return cell.value ? cell.value : "";
};

const LastDate = (cell) => {
    return cell.value ? formateDate(cell.value,'DD/MM/Y hh:mm a') : "";
};

const Status = (cell) => {
    switch(cell.value) {
        case false:
            return <Badge className="bg-danger">disable</Badge>
        case true:
            return <Badge className="bg-success">enable</Badge>
    }
};

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};


export { CategoryId, CategoryName,CommomCell, PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount,Points,Type,LastDate, Status };

function roundToTwo(num) {
    return +(Math.ceil(num + "e+3")  + "e-3");
}