import { SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION:
      state = { ...state };
      break;
    case SEND_NOTIFICATION_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case SEND_NOTIFICATION_ERROR:
      state = { ...state, error: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default notification;
