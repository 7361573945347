import toastr from "toastr";
import "toastr/build/toastr.min.css";


export function showToast(title,message,toastType) {
    //position class
    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: 5000,
        extendedTimeOut: 1000,
        closeButton: true,
        debug: false,
        progressBar: true,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        showDuration: 300,
        hideDuration: 1000
    };

    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
}

export function clearToast() {
    toastr.clear();
}