import React from "react";

import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//Users
import Users from "../pages/UserPages/Users/index";

//Withdrawal
import Withdrawal from "../pages/Withdrawal/index";

//Profile
import Profile from "../pages/Profile/index";


//Notification
import Notification from "../pages/Notification/index";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Category from "../pages/Category";
import UserDetails from "pages/UserPages/UserDetails";
import CommonSettings from "pages/Settings/CommonSettings";
import AppUpdateSettings from "pages/Settings/AppUpdateSettings";
import AdsSettings from "pages/Settings/AdsSettings";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";



const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  //Users
  { path: "/users", component: <Users /> },
  { path: "/user-details/:id", component: <UserDetails /> },

  //Withdrawal
  { path: "/withdrawal", component: <Withdrawal /> },

  //Category
  { path: "/category", component: <Category /> },

  //CommonSettings
  { path: "/common-settings", component: <CommonSettings /> },
  { path: "/app-update-settings", component: <AppUpdateSettings /> },
  { path: "/ads-settings", component: <AdsSettings /> },

  //Profile
  { path: "/profile", component: <Profile /> },

  {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },

  //Notification
  { path: "/notification", component: <Notification /> }
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];


export { authProtectedRoutes, publicRoutes };
