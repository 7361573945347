import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import QRCode from "react-qr-code";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
    WithdrawalId, WithdrawalName, PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount, Points, PostedDate, LastDate, Status
}
    from "./app-update-settings-col";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Form,
    Input,
    Label,
    Card,
    CardBody,
    Container,
    CardTitle,
    FormFeedback, Spinner


} from "reactstrap";

import {
    getSettings as onGetSettings,
    upateSettings as onUpateSettings
} from "store/actions";

function AppUpdateSettings() {

    //meta title
    document.title = "App Update Settings | SCRATCH IT & WIN IT";

    const [appUpdateSettings, setAppUpdateSettings] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    var initialValues = {};

    const dispatch = useDispatch();
    const { settings, isUpdateLoading } = useSelector(state => ({
        settings: state.SettingsReducer.settings,
        isUpdateLoading: state.SettingsReducer.isUpdateLoading
    }));

    useEffect(() => {
        if (isEmpty(appUpdateSettings)) {
            dispatch(onGetSettings());
        }
    }, [dispatch]);

    const removeCommaFirstAndLast = (data) => {
        return data.replace(/^,*|,*$/g, '');
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter update dialog title"),
            // payoutFrom: Yup.string().required("Please Enter Payout Form"),
            // status: Yup.string().required("Please Enter Your Status"),
        }),
        validate: (values) => {
            let errors = {};
            if (values.status === 'pending') {
                errors.status = "If you want to edit withdrawal request then you select another value for example select the success, cancel or failed."
            }
            return errors;
        },
        onSubmit: (values) => {

            const updateAppSettings = {
                "update": {
                    "android_version": values.androidVersion,
                    "ios_version": values.iosVersion,
                    "force_update": values.forceUpdate,
                    "android_link": values.androidLink,
                    "ios_link": values.iosLink,
                    "update_info": {
                        "title": values.title,
                        "content": values.content,
                        "bannedVersions": removeCommaFirstAndLast(values.bannedVersions).split(','),
                        "forceUpdateContent": values.forceUpdateContent,
                        "changelogsText": values.changelogsText,
                        "changelogs": removeCommaFirstAndLast(values.changelogs).split(','),
                        "failToOpenStoreError": values.failToOpenStoreError
                    }
                },
                "supported_country_code": removeCommaFirstAndLast(values.supportedCountryCode).split(','),
                "referral_point": values.referralPoint,
                "note": {
                    "isShow": values.isShow,
                    "note": values.note,
                    "textColor": values.textColor,
                    "containerBgColor": values.containerBgColor
                }
            };

            console.log(updateAppSettings);

            // update app settings
            dispatch(onUpateSettings({
                "type": "common",
                "data": updateAppSettings
            }));
            // validation.resetForm();
            // editToggle();
        },
    });



    useEffect(() => {
        if (!isEmpty(settings)) {
            setAppUpdateSettings(settings);
            initialValues = {
                androidVersion: settings.common.update.android_version,
                iosVersion: settings.common.update.ios_version,
                androidLink: settings.common.update.android_link,
                iosLink: settings.common.update.ios_link,
                title: settings.common.update.update_info.title,
                content: settings.common.update.update_info.content,
                bannedVersions: settings.common.update.update_info.bannedVersions.toString(),
                forceUpdateContent: settings.common.update.update_info.forceUpdateContent,
                changelogsText: settings.common.update.update_info.changelogsText,
                changelogs: settings.common.update.update_info.changelogs.toString(),
                failToOpenStoreError: settings.common.update.update_info.failToOpenStoreError,
                supportedCountryCode: settings.common.supported_country_code.toString(),
                referralPoint: settings.common.referral_point,
                forceUpdate: settings.common.update.force_update,
                note: settings.common.note.note,
                textColor: settings.common.note.textColor,
                containerBgColor: settings.common.note.containerBgColor,
                isShow: settings.common.note.isShow,
            };
            validation.setValues({
                ...initialValues
            });
        }
    }, [settings]);



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Settings" breadcrumbItem="App Update" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">App Update Settings</CardTitle>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Android Version</Label>
                                                    <Input
                                                        name="androidVersion"
                                                        type="text"
                                                        placeholder="Android Version"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.androidVersion || ""}
                                                        invalid={
                                                            validation.touched.androidVersion && validation.errors.androidVersion ? true : false
                                                        }
                                                    />
                                                    {validation.touched.androidVersion && validation.errors.androidVersion ? (
                                                        <FormFeedback type="invalid">{validation.errors.androidVersion}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">iOS Version</Label>
                                                    <Input
                                                        name="iosVersion"
                                                        type="text"
                                                        placeholder="iOS Version"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.iosVersion || ""}
                                                        invalid={
                                                            validation.touched.iosVersion && validation.errors.iosVersion ? true : false
                                                        }
                                                    />
                                                    {validation.touched.iosVersion && validation.errors.iosVersion ? (
                                                        <FormFeedback type="invalid">{validation.errors.iosVersion}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Android Link</Label>
                                                    <Input
                                                        name="androidLink"
                                                        type="text"
                                                        placeholder="Android Link"
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.androidLink || ""}

                                                    />

                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">iOS Link</Label>
                                                    <Input
                                                        name="iosLink"
                                                        type="text"
                                                        placeholder="iOS Link"
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.iosLink || ""}

                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">
                                            <Label className="form-label">Title</Label>
                                            <Input
                                                name="title"
                                                type="text"
                                                placeholder="Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.title || ""}
                                                invalid={
                                                    validation.touched.title && validation.errors.title ? true : false
                                                }

                                            />
                                            {validation.touched.title && validation.errors.title ? (
                                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Content</Label>
                                            <Input
                                                name="content"
                                                type="text"
                                                placeholder="Content"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.content || ""}
                                                invalid={
                                                    validation.touched.content && validation.errors.content ? true : false
                                                }
                                            />
                                            {validation.touched.content && validation.errors.content ? (
                                                <FormFeedback type="invalid">{validation.errors.content}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Banned Versions(comma separated)</Label>
                                            <Input
                                                name="bannedVersions"
                                                type="text"
                                                placeholder="Banned Versions"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.bannedVersions || ""}

                                            />

                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Force Update Content</Label>
                                            <Input
                                                name="forceUpdateContent"
                                                type="text"
                                                placeholder="Force Update Content"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.forceUpdateContent || ""}

                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Change Logs Text</Label>
                                            <Input
                                                name="changelogsText"
                                                type="text"
                                                placeholder="Change Logs Text"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.changelogsText || ""}

                                            />
                                        </div>


                                        <div className="mb-3">
                                            <Label className="form-label">Change logs(comma separated)</Label>
                                            <Input
                                                name="changelogs"
                                                type="text"
                                                placeholder="Change logs"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.changelogs || ""}

                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Fail To Open Store Error</Label>
                                            <Input
                                                name="failToOpenStoreError"
                                                type="text"
                                                placeholder="Fail To Open Store Error"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.failToOpenStoreError || ""}

                                            />
                                        </div>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Supported Country Code(comma separated)</Label>
                                                    <Input
                                                        name="supportedCountryCode"
                                                        type="text"
                                                        placeholder="Supported Country Code"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.supportedCountryCode || ""}
                                                        invalid={
                                                            validation.touched.supportedCountryCode && validation.errors.supportedCountryCode ? true : false
                                                        }
                                                    />
                                                    {validation.touched.supportedCountryCode && validation.errors.supportedCountryCode ? (
                                                        <FormFeedback type="invalid">{validation.errors.supportedCountryCode}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Referral Points</Label>
                                                    <Input
                                                        name="referralPoint"
                                                        type="text"
                                                        placeholder="Referral Point"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.referralPoint || ""}
                                                        invalid={
                                                            validation.touched.referralPoint && validation.errors.referralPoint ? true : false
                                                        }
                                                    />
                                                    {validation.touched.referralPoint && validation.errors.referralPoint ? (
                                                        <FormFeedback type="invalid">{validation.errors.referralPoint}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>


                                        <div className="mb-3">
                                            <div onClick={() => {
                                                const newCheckboxValue = !validation.values.forceUpdate;
                                                validation.setFieldValue('forceUpdate', newCheckboxValue);
                                            }} className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    name="forceUpdate"
                                                    checked={validation.values.forceUpdate ?? false}
                                                    readOnly

                                                />
                                                <Label
                                                    className="form-check-Label"
                                                    htmlFor="formrow-customCheck"
                                                >
                                                    Force Update
                                                </Label>
                                            </div>
                                        </div>

                                        <CardTitle className="mb-3 pt-4 border-top">Dashboard Note</CardTitle>

                                        <div className="mb-4">
                                            <Label className="form-label">Note</Label>
                                            <Input
                                                name="note"
                                                type="text"
                                                placeholder="note"
                                                validate={{
                                                    required: { value: false },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.note || ""}

                                            />
                                        </div>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Container Background Color</Label>
                                                    <Input
                                                        name="containerBgColor"
                                                        type="text"
                                                        placeholder="Container Background Color"
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.containerBgColor || ""}

                                                    />

                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Text Color</Label>
                                                    <Input
                                                        name="textColor"
                                                        type="text"
                                                        placeholder="Text Color"
                                                        validate={{
                                                            required: { value: false },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.textColor || ""}

                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">
                                            <div onClick={() => {
                                                const newCheckboxValue = !validation.values.isShow;
                                                validation.setFieldValue('isShow', newCheckboxValue);
                                            }} className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    name="isShow"
                                                    checked={validation.values.isShow ?? false}
                                                    readOnly

                                                />
                                                <Label
                                                    className="form-check-Label"
                                                    htmlFor="formrow-customCheck"
                                                >
                                                    Show note
                                                </Label>
                                            </div>
                                        </div>

                                        <div>
                                            <button disabled={isUpdateLoading} type="submit" className="btn btn-primary w-md" >
                                                {/* <div className="mb-2" style={{ textAlign: 'center', flex: 1, alignItems: 'center', width: "20px", height: "20px" }}>
                                                    <Spinner className="ms-2" color="white" />
                                                </div> */}
                                                Submit
                                            </button>
                                        </div>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}


export default AppUpdateSettings;