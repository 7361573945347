import {
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAIL,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL

} from "./actionTypes";

const INIT_STATE = {
    settings: {},
    error: {},
    jobApply: [],
    isUpdateLoading:false
}

const SettingsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_SETTINGS:
            return {
                ...state,
                isUpdateLoading: true,
            };
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
            };
        case GET_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                isUpdateLoading: false,
                settings: action.payload,
            };
        case UPDATE_SETTINGS_FAIL:
            return {
                ...state,
                isUpdateLoading: false,
                error: action.payload,
            };
        default:
            return state
    }
}

export default SettingsReducer;