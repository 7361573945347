import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import QRCode from "react-qr-code";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';


import {
    CategoryId, CategoryName,CommomCell,  PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount, Points, PostedDate, LastDate, Status
}
    from "./categoryListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,Alert

} from "reactstrap";

import {
    getCategoryList as onGetCategoryList,
    updateCategoryList as onUpdateCategoryList,
    addCategoryList as onAddCategoryList,
    deleteCategoryList as onDeleteCategoryList,
} from "store/actions";


function Category() {

    //meta title
    document.title = "Category List | SCRATCH IT & WIN IT";

    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState(null);

    const dispatch = useDispatch();
    const { categories } = useSelector(state => ({
        categories: state.CategoryReducer.categories,
    }));

    useEffect(() => {
        if (categories && !categories.length) {
            dispatch(onGetCategoryList());
        }
    }, [dispatch, categories]);

    useEffect(() => {
        setCategoryList(categories);
    }, [categories]);

    useEffect(() => {
        if (!isEmpty(categories) && !!isEdit) {
            setCategoryList(categories);
            setIsEdit(false);
        }
    }, [categories]);

    //delete category
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (category) => {
        setCategory(category);
        setDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        if (category && category.id) {
            dispatch(onDeleteCategoryList({"id":category.id}));
            setDeleteModal(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'id',
                filterable: true,
                Cell: (cellProps) => {
                    return <CategoryId {...cellProps} />;
                }
            },
            {
                Header: 'Title',
                accessor: 'name',
                filterable: true,
                Cell: (cellProps) => {
                    return <CommomCell {...cellProps} />;
                }
            },
            {
                Header: 'Sub title',
                accessor: 'sub_title',
                filterable: true,
                Cell: (cellProps) => {
                    return <CommomCell {...cellProps} />;
                }
            },
            {
                Header: 'Limit',
                accessor: 'limit',
                filterable: true,
                Cell: (cellProps) => {
                    return <CommomCell {...cellProps} />;
                }
            },
            {
                Header: 'Min Point',
                accessor: 'min_point',
                filterable: true,
                Cell: (cellProps) => {
                    return <CommomCell {...cellProps} />;
                }
            },
            {
                Header: 'Max Point',
                accessor: 'max_point',
                filterable: true,
                Cell: (cellProps) => {
                    return <CommomCell {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                
                disableFilters: true,
            
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
         
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                    
                            <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-info"
                                            onClick={() => {
                                                setIsEdit(true);
                                                setEditModal(true)
                                                const categoryData = cellProps.row.original;
                                                setCategory(categoryData);
                                                
                                            }}
                                        >
                                            <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                            <UncontrolledTooltip placement="top" target="edittooltip">
                                                Edit
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>

                                    <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const category = cellProps.row.original;
                                        onClickDelete(category);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>



                        </ul>
                    );
                }}
            // },
        ],
        []
    );


    const editToggle = () => {
        if (editModal) {
            setEditModal(false);
            // setJob(null);
            setCategory(null);
        } else {
            setEditModal(true);
        }
    };

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            title: (category && category.name) || '',
            subTitle:  (category && category.sub_title) || '',
            limit:(category && category.limit) || '',
            minPoint:(category && category.min_point) || '',
            maxPoint:(category && category.max_point) || '',
            position:(category && category.position) || '',
            status: (category && category.is_active ? "enable": "disable") || '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter category title."),
            subTitle: Yup.string().required("Please enter category sub title."),
            limit: Yup.string().required("Please enter card limit."),
            minPoint: Yup.string().required("Please enter card min point."),
            maxPoint: Yup.string().required("Please enter card max point."),
            position: Yup.string().required("Please enter category position."),
            status: Yup.string().required("Please select status."),
        }),
        
        onSubmit: (values) =>  {
            const updateCategory = {
                "name":values.title,
                "sub_title":values.subTitle,
                "limit":values.limit,
                "min_point":values.minPoint,
                "max_point":values.maxPoint,
                "position":values.position,
                "is_active":values.status == "enable" ? true : false,
                "id":category && category.id || 0
            };
            // update Category
            if(isEdit){
                dispatch(onUpdateCategoryList(updateCategory));
            }else{
                dispatch(onAddCategoryList(updateCategory));
            }
            validation.resetForm();
            editToggle();
        },
    });

    const { error } = useSelector(state => {

        return {
          error: state.CategoryReducer.error,
        };
      });

    const handleAddButtonClick = () => {
        setEditModal(true)
        setCategory(null);
        setIsEdit(false)
    }


    return (
        <React.Fragment>
            <DeleteModal
            title={'Are you sure you want to permanently erase the category.'}
                show={deleteModal}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setDeleteModal(false)}
            />
           
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Category" breadcrumbItem="Category" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Category Lists</h5>
                                        {/* <div className="flex-shrink-0">
                                            <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Add New Job</Link>
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                            <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div> */}
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={categories}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        handleAddButtonClick={handleAddButtonClick}
                                        // handleJobClicks={handleJobClicks}
                                        // handleUserClick= {}
                                        addButttonTitle={"Add New Category"}
                                        isJobListGlobalFilter={false}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                   

                    <Modal isOpen={editModal} toggle={editToggle} className="modal-dialog-centered">
                        <ModalHeader toggle={editToggle} tag="h4">
                           Edit Category
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                        
                                            <Label className="form-label">Title</Label>
                                            <Input
                                                name="title"
                                                type="text"
                                                placeholder="Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.title || ""}
                                                invalid={
                                                    validation.touched.title && validation.errors.title ? true : false
                                                }
                                            />
                                            {validation.touched.title && validation.errors.title ? (
                                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Sub Title</Label>
                                            <Input
                                                name="subTitle"
                                                type="text"
                                                placeholder="Sub Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.subTitle || ""}
                                                invalid={
                                                    validation.touched.subTitle && validation.errors.subTitle ? true : false
                                                }
                                            />
                                            {validation.touched.subTitle && validation.errors.subTitle ? (
                                                <FormFeedback type="invalid">{validation.errors.subTitle}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Card Limit</Label>
                                            <Input
                                                name="limit"
                                                type="text"
                                                placeholder="Card Limit"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.limit || ""}
                                                invalid={
                                                    validation.touched.limit && validation.errors.limit ? true : false
                                                }
                                            />
                                            {validation.touched.limit && validation.errors.limit ? (
                                                <FormFeedback type="invalid">{validation.errors.limit}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Min Point</Label>
                                            <Input
                                                name="minPoint"
                                                type="text"
                                                placeholder="Min Point"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.minPoint || ""}
                                                invalid={
                                                    validation.touched.minPoint && validation.errors.minPoint ? true : false
                                                }
                                            />
                                            {validation.touched.minPoint && validation.errors.minPoint ? (
                                                <FormFeedback type="invalid">{validation.errors.minPoint}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Max Point</Label>
                                            <Input
                                                name="maxPoint"
                                                type="text"
                                                placeholder="Max Point"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.maxPoint || ""}
                                                invalid={
                                                    validation.touched.maxPoint && validation.errors.maxPoint ? true : false
                                                }
                                            />
                                            {validation.touched.maxPoint && validation.errors.maxPoint ? (
                                                <FormFeedback type="invalid">{validation.errors.maxPoint}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Position</Label>
                                            <Input
                                                name="position"
                                                type="text"
                                                placeholder="Position"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.position || ""}
                                                invalid={
                                                    validation.touched.position && validation.errors.position ? true : false
                                                }
                                            />
                                            {validation.touched.position && validation.errors.position ? (
                                                <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>enable</option>
                                                <option>disable</option>
                                            </Input>
                                            {(() => {
                                                
                                                if (validation.touched.status && validation.errors.status) {
                                                    return <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                                }
                                                return null
                                            })()}

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                </div>
            </div>
        </React.Fragment>
    );
}


function roundToTwo(num) {
    return +(Math.ceil(num + "e+3") + "e-3");
}


export default Category;