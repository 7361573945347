import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "components/Common/withRouter";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    Button,
    Alert
} from "reactstrap";

import {
    editProfile
} from "store/actions";

import user1 from "../../assets/images/users/avatar-1.jpg";


function Profile() {

    //meta title
    document.title = "Profile | SCRATCH IT & WIN IT";

    const dispatch = useDispatch();

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [id, setId] = useState(1);

    const { error, success } = useSelector(state => ({
        error: state.Profile.error,
        success: state.Profile.success,
    }));

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setname(obj.name);
            setemail(obj.email);
            setId(obj.id);
        }
        if(success !== undefined && success !== ""){
            validation.setFieldValue('password', '');  
        }
    }, [dispatch, success]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: name || '',
            email: email || '',
            id: id || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter your name"),
            email: Yup.string().required("Please enter your email address"),
        }),
        onSubmit: (values) => {
            dispatch(editProfile(values));
            
        }
    });

    return ( 
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Profile" breadcrumbItem="Profile" />
                    <Row>
                        <Col lg="12">
                            {error && error ? <Alert color="danger">{error}</Alert> : null}
                            {success ? <Alert color="success">{success}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="ms-2">
                                            <img
                                                src={user1}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <div className="flex-grow-1 align-self-center ms-2">
                                            <div className="text-muted">
                                                <h5>{name}</h5>
                                                <p className="mb-1">{email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h4 className="card-title mb-4">Update Profile</h4>
                    <Card>
                        <CardBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="form-group">
                                    <Label className="form-label">Name</Label>
                                    <Input
                                        name="name"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}

                                    <Label className="form-label mt-3">Email</Label>
                                    <Input
                                        name="email"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter your email"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}

                                    <Label className="form-label mt-3">New Password</Label>
                                    <Input
                                        name="password"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter new password"
                                        type="password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password || ""}        
                                    />
                                
                                    <Input name="id" value={id} type="hidden" />
                                </div>
                                <div className="text-center mt-4">
                                    <Button type="submit" color="danger">
                                        Update Profile
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
}


function roundToTwo(num) {
    return +(Math.ceil(num + "e+3") + "e-3");
}


export default withRouter(Profile);