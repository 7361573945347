import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
    UserId, UserName, Email, CountryCode, MobileNumber, RefferedCode, RefferedBy, Type, PostedDate, LastDate, Status
}
    from "./UserListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import {
    getUserList as onGetUserList,
    deleteUser as onDeleteUser,
    editUser as onEditUser,
} from "store/actions";

function Users() {

    //meta title
    document.title = "Users List | SCRATCH IT & WIN IT";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [userList, setUsersList] = useState([]);
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();
    const { users } = useSelector(state => ({
        users: state.UsersReducer.users,
    }));

    useEffect(() => {
        if (users && !users.length) {
            dispatch(onGetUserList());
        }
    }, [dispatch, users]);

    useEffect(() => {
        setUsersList(users);
    }, [users]);

    useEffect(() => {
        if (!isEmpty(users) && !!isEdit) {
            setUsersList(users);
            setIsEdit(false);
        }
    }, [users]);

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'id',
                filterable: true,
                Cell: (cellProps) => {
                    return <UserId {...cellProps} />;
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                filterable: true,
                Cell: (cellProps) => {
                    return <UserName {...cellProps} />;
                }
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: true,
                Cell: (cellProps) => {
                    return <Email {...cellProps} />;
                }
            },
            {
                Header: 'Country Code',
                accessor: 'country_code',
                filterable: true,
                Cell: (cellProps) => {
                    return <CountryCode {...cellProps} />;
                }
            },
            {
                Header: 'Mobile Number',
                accessor: 'phone',
                filterable: true,
                Cell: (cellProps) => {
                    return <MobileNumber {...cellProps} />;
                }
            },
            {
                Header: 'Reffered Code',
                accessor: 'refferedCode',
                Cell: (cellProps) => {
                    return <RefferedCode {...cellProps} />;
                }
            },
            {
                Header: 'Reffered By',
                accessor: 'refferedBy',
                Cell: (cellProps) => {
                    return <RefferedBy {...cellProps} />;
                }
            },
            {
                Header: 'points',
                accessor: 'point',
                Cell: (cellProps) => {
                    return <RefferedBy {...cellProps} />;
                }
            },
            {
                Header: 'Last Date',
                accessor: 'createdAt',
                Cell: (cellProps) => {
                    return <LastDate {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'active',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,

                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        onClickStatus(cellProps.row.original);
                                    }}
                                >
                                    <i className={cellProps.row.original.active !== 1 ? "mdi mdi-play" : "mdi mdi-pause"  } id="edittooltip" />
                                    
                                </Link>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to={`/user-details/${cellProps.row.original.id}`} className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    //delete user
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (user) => {
        setUser(user);
        setDeleteModal(true);
    };

    const handleDeleteUser = () => {
        if (user && user.id) {
            dispatch(onDeleteUser({ "id": user.id }));
            setDeleteModal(false);
        }
    };

    const onClickStatus = (user) => {
        if (user && user.id) {
            var active = user.active === 1 ? false : true;
           
            dispatch(onEditUser({ "id": user.id, "active": active}));
        }  
    };


    return (
        <React.Fragment>
            <DeleteModal
                title={'Are you sure you want to permanently delete the user.'}
                show={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Users" breadcrumbItem="Users" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Users Lists</h5>
                                        {/* <div className="flex-shrink-0">
                                            <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Add New Job</Link>
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                            <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div> */}
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={users}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        // handleJobClicks={handleJobClicks}
                                        isJobListGlobalFilter={false}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    );
}


export default Users;