//LOGIN
export const POST_LOGIN = "/signin";


//USER
export const GET_USERS = "/get_users";
export const GET_USER_DETAIL = "/get_user_details";
export const DELETE_USER = "/delete_user";
export const EDIT_USER = "/edit_user";


//USER
export const GET_WITHDRAWAL_REQUEST = "/get_withdrawal_request";

//WITHDRAWAL
export const UPDATE_WITHDRAWAL_LIST = "/update_withdrawal_status";

//Settings
export const GET_SETTINGS = "/get_settings";
export const UPDATE_SETTINGS = "/update_settings";

//Category
export const GET_CATEGORY_REQUEST = "/get_scratch_card_category";
export const UPDATE_CATEGORY_LIST = "/update_scratch_card_category";
export const ADD_CATEGORY_LIST = "/add_scratch_card_category";
export const DELETE_CATEGORY_LIST = "/delete_scratch_card_category";

//Dashboard
export const GET_DASHBOARD_DATA = "/get_dashboard";

//Edit Profile
export const EDIT_PROFILE = "/edit_profile";

//Send Notification
export const SEND_NOTIFICATION = "/send_notification";




