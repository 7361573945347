import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CATEGORY_LIST,UPDATE_CATEGORY_LIST,ADD_CATEGORY_LIST,DELETE_CATEGORY_LIST } from "./actionTypes";
import {
    getCategoryListSuccess,
    getCategoryListFail,
    updateCategoryListFail,
    updateCategoryListSuccess,
    addCategoryListFail,
    addCategoryListSuccess,
    deleteCategoryListFail,
    deleteCategoryListSuccess
} from "./actions"

import {
    getCategoryList,
    updateCategoryist,
    addCategoryist,
    deleteCategoryList
} from "helpers/backend_helper";

import {
    showToast,
    clearToast
} from "helpers/toastr_helper";

function* fetchCategoryList() {
    try {
        const response = yield call(getCategoryList)
        if(response.status){ 
            yield put(getCategoryListSuccess(response.data))
        }else{
            yield put(getCategoryListFail(response.message))
        }
    } catch (error) {
        yield put(getCategoryListFail(error))
    }
}

function* onUpdateCategoryList({ payload: data }) {
    try {
        const response = yield call(updateCategoryist, data)
        debugger
        if(response.status){ 
            yield put(updateCategoryListSuccess(response.data))
        }else{
            showToast("error",response.message,"error"),
            yield put(updateCategoryListFail(response.message))
        }
    } catch (error) {
        showToast("error",error,"error"),
        yield put(updateCategoryListFail(error))
    }
}

function* onAddCategoryList({ payload: data }) {
    try {
        const response = yield call(addCategoryist, data)
        if(response.status){ 
            
            yield put(addCategoryListSuccess(response.data))
        }else{
            showToast("error",response.message,"error"),
            yield put(addCategoryListFail(response.message))
        }
    } catch (error) {
        showToast("error",error,"error"),
        yield put(addCategoryListFail(error))
    }
}

function* onDeleteCategoryList({ payload: data }) {
    try {
        const response = yield call(deleteCategoryList, data)
        if(response.status){ 
            showToast("Success",response.message),
            yield put(deleteCategoryListSuccess(response.data))
        }else{
            showToast("error",response.message,"error"),
            yield put(deleteCategoryListFail(response.message))
        }
    } catch (error) {
        showToast("error",error,"error"),
        yield put(deleteCategoryListFail(error))
    }
}

function* categorySaga() {
    yield takeEvery(GET_CATEGORY_LIST, fetchCategoryList)
    yield takeEvery(UPDATE_CATEGORY_LIST, onUpdateCategoryList)
    yield takeEvery(ADD_CATEGORY_LIST, onAddCategoryList)
    yield takeEvery(DELETE_CATEGORY_LIST, onDeleteCategoryList)
}

export default categorySaga;