import { SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_ERROR } from "./actionTypes"

export const sendNotification = data => {
  return {
    type: SEND_NOTIFICATION,
    payload: data,
  }
}

export const sendNotificationSuccess = data => {
  return {
    type: SEND_NOTIFICATION_SUCCESS,
    payload: data,
  }
}

export const sendNotificationError = error => {
  return {
    type: SEND_NOTIFICATION_ERROR,
    payload: error,
  }
}
