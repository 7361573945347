import React, { useEffect, useMemo, useState } from "react";

import {
    Col,
    Row,
    Form,
    Input,
    Label,
    CardTitle,
    NavLink,
    NavItem, Nav,
    Spinner,
    Container

} from "reactstrap";
import PropTypes from 'prop-types'
import classnames from "classnames";
import { isEmpty } from "lodash";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import {
    getSettings as onGetSettings,
    upateSettings as onUpateSettings
} from "store/actions";


function UnityAdsSettings(props) {
    var initialValues = {};

    const [adSettings, setAdSettings] = useState({});
    const dispatch = useDispatch();
    const [settingsTypeActiveTab, setSettingsTypeActiveTab] = useState("1");
    const settingsTypeToggleTab = tab => {
    
        if (tab === "1") {
            setSettings(props.settings.android);
        } else {
            setSettings(props.settings.ios);
        }
        if (settingsTypeActiveTab !== tab) {
            setSettingsTypeActiveTab(tab);

        }
    };

    const { isUpdateLoading } = useSelector(state => ({
       
        isUpdateLoading: state.SettingsReducer.isUpdateLoading
    }));

    useEffect(() => {
        if (!isEmpty(props.settings)) {
            if (settingsTypeActiveTab === "1") {
                setSettings(props.settings.android);
            } else {
                setSettings(props.settings.ios);
            }
        }
    }, []);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: initialValues,
        // validationSchema: Yup.object({
        //     title: Yup.string().required("Please enter update dialog title"),
        //     // payoutFrom: Yup.string().required("Please Enter Payout Form"),
        //     // status: Yup.string().required("Please Enter Your Status"),
        // }),
        // validate: (values) => {
        //     let errors = {};
        //     if (values.status === 'pending') {
        //         errors.status = "If you want to edit withdrawal request then you select another value for example select the success, cancel or failed."
        //     }
        //     return errors;
        // },
        onSubmit: (values) => {
            var tmpSettings = props.mainSettings;

            tmpSettings.multiple_ad_support.unity_ad = true;
            tmpSettings.multiple_ad_support.google_ad = false;
            tmpSettings.multiple_ad_support.ad_colony = false;

            const updateAdsSettings = {
                "global": {
                    "sdk_key": values.sdkKey,
                    "app_open": values.appOpen,
                    "app_open_id": values.appOpenId,
                    "banner": values.banner,
                    "banner_id": values.bannerId,
                    "interstitial": values.interstitial,
                    "interstitial_id": values.interstitialId,
                    "native": values.native,
                    "native_id": values.nativeId,
                    "reward": values.reward,
                    "reward_id": values.rewardId,
                    "version_no": 1,
                    "native_ad_interval": 0,
                    "reward_coins": values.rewardCoins,
                    "gdpr": "0"
                },
                "screens": {
                    "daily_reward": {
                        "interval": values.dailyRewardInterval,
                        "show": values.dailyReward
                    },
                    "scratch_card_silver": {
                        "interval": values.scratchCardSilverInterval,
                        "show": values.scratchCardSilver
                    },
                    "scratch_card_platinum": {
                        "interval": values.scratchCardPlatinumInterval,
                        "show": values.scratchCardPlatinum
                    },
                    "scratch_card_golden": {
                        "interval": values.scratchCardGoldenInterval,
                        "show": values.scratchCardGolden
                    },
                    "spin_to_win": {
                        "interval": values.spinToWinInterval,
                        "show": values.spinToWin
                    },
                    "splash": {
                        "interval": values.splashInterval,
                        "show": values.splash
                    }
                }
            };
            if (settingsTypeActiveTab === "1") {
                tmpSettings.multiple_ad_support.unity_ad_settings.android = updateAdsSettings;
            } else { 
                tmpSettings.multiple_ad_support.unity_ad_settings.ios = updateAdsSettings;
            }
            // update app settings
            dispatch(onUpateSettings({
                "type": "ad",
                "data": tmpSettings
            }));
        },
    });


    const setSettings = setting => {
        console.log(setting.global);
        initialValues = {
            "appOpen": false,
            "appOpenId": "",
            "banner": setting.global.banner,
            "bannerId": setting.global.banner_id,
            "interstitial": setting.global.interstitial,
            "interstitialId": setting.global.interstitial_id,
            "native": false,
            "nativeId": "",
            "reward": setting.global.reward,
            "rewardId": setting.global.reward_id,
            "rewardCoins": setting.global.reward_coins,
            "dailyReward": setting.screens.daily_reward.show,
            "dailyRewardInterval": setting.screens.daily_reward.interval,
            "scratchCardSilver": setting.screens.scratch_card_silver.show,
            "scratchCardSilverInterval": setting.screens.scratch_card_silver.interval,
            "scratchCardPlatinum": setting.screens.scratch_card_platinum.show,
            "scratchCardPlatinumInterval": setting.screens.scratch_card_platinum.interval,
            "scratchCardGolden": setting.screens.scratch_card_golden.show,
            "scratchCardGoldenInterval": setting.screens.scratch_card_golden.interval,
            "splash": false,
            "splashInterval": 1,
            "spinToWin": setting.screens.spin_to_win.show,
            "spinToWinInterval": setting.screens.spin_to_win.interval,
            "sdkKey":  setting.global.sdk_key,
        }
        validation.setValues({
            ...initialValues
        });
        setAdSettings(setting);
    }

    return (
        <React.Fragment>

            <Container fluid={true}>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <div className="crypto-buy-sell-nav">
                        <Nav tabs className="nav-tabs-custom" role="tablist">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: settingsTypeActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        settingsTypeToggleTab("1");
                                    }}
                                >
                                    Android
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: settingsTypeActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        settingsTypeToggleTab("2");
                                    }}
                                >
                                    iOS
                                </NavLink>
                            </NavItem>
                        </Nav>

                    </div>



                    <CardTitle className="mb-4 mt-4">{(() => {
                        return "Global Settings"
                    })()}</CardTitle>

                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Sdk Key</Label>
                                <Input
                                    name="sdkKey"
                                    type="text"
                                    placeholder="Sdk Key"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sdkKey || ""}
                                />

                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Banner Id</Label>
                                <Input
                                    name="bannerId"
                                    type="text"
                                    placeholder="Banner Id"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.bannerId || ""}

                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label className="form-label">Interstitial Id</Label>
                                <Input
                                    name="interstitialId"
                                    type="text"
                                    placeholder="Interstitial Id"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.interstitialId || ""}

                                />

                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Reward Id</Label>
                                <Input
                                    name="rewardId"
                                    type="text"
                                    placeholder="Reward Id"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.rewardId || ""}

                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Reward Coins</Label>
                                <Input
                                    name="rewardCoins"
                                    type="text"
                                    placeholder="Reward Coins"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.rewardCoins || ""}

                                />
                            </div>
                        </Col>

                    </Row>

                    <Row className="mt-2">
                        

                        <Col md={2}>



                            <div className="mb-3">
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.banner;
                                    validation.setFieldValue('banner', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="banner"
                                        checked={validation.values.banner ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Banner Ad
                                    </Label>
                                </div>
                            </div>
                        </Col>

                        <Col md={2}>



                            <div className="mb-3">
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.reward;
                                    validation.setFieldValue('reward', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="reward"
                                        checked={validation.values.reward ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Reward Ad
                                    </Label>
                                </div>
                            </div>
                        </Col>

                        <Col md={2}>

                            <div className="mb-3">
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.interstitial;
                                    validation.setFieldValue('interstitial', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="interstitial"
                                        checked={validation.values.interstitial ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Interstitial Ad
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <CardTitle className="mb-4">Screen Settings</CardTitle>



                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Daily Reward Interval</Label>
                                <Input
                                    name="dailyRewardInterval"
                                    type="text"
                                    placeholder="Daily Reward Interval"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dailyRewardInterval || ""}

                                />

                            </div>
                        </Col>
                        <Col md={6} className="align-self-center mt-3">
                            <div>
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.dailyReward;
                                    validation.setFieldValue('dailyReward', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="dailyReward"
                                        checked={validation.values.dailyReward ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Daily Reward Ads
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Scratch Card Silver Interval</Label>
                                <Input
                                    name="scratchCardSilverInterval"
                                    type="text"
                                    placeholder="Scratch Card Silver Interval"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.scratchCardSilverInterval || ""}

                                />

                            </div>
                        </Col>
                        <Col md={6} className="align-self-center mt-3">
                            <div>
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.scratchCardSilver;
                                    validation.setFieldValue('scratchCardSilver', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="scratchCardSilver"
                                        checked={validation.values.scratchCardSilver ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Scratch Card Silver Ads
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Scratch Card Platinum Interval</Label>
                                <Input
                                    name="scratchCardPlatinumInterval"
                                    type="text"
                                    placeholder="Scratch Card Platinum Interval"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.scratchCardPlatinumInterval || ""}

                                />

                            </div>
                        </Col>
                        <Col md={6} className="align-self-center mt-3">
                            <div>
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.scratchCardPlatinum;
                                    validation.setFieldValue('scratchCardPlatinum', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="scratchCardPlatinum"
                                        checked={validation.values.scratchCardPlatinum ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Scratch Card Platinum Ads
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Scratch Card Golden Interval</Label>
                                <Input
                                    name="scratchCardGoldenInterval"
                                    type="text"
                                    placeholder="Scratch Card Golden Interval"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.scratchCardGoldenInterval || ""}

                                />

                            </div>
                        </Col>
                        <Col md={6} className="align-self-center mt-3">
                            <div>
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.scratchCardGolden;
                                    validation.setFieldValue('scratchCardGolden', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="scratchCardGolden"
                                        checked={validation.values.scratchCardGolden ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Scratch Card Golden Ads
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Spin To Win Interval</Label>
                                <Input
                                    name="spinToWinInterval"
                                    type="text"
                                    placeholder="Spin To Win Interval"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.spinToWinInterval || ""}

                                />

                            </div>
                        </Col>
                        <Col md={6} className="align-self-center mt-3">
                            <div>
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.spinToWin;
                                    validation.setFieldValue('spinToWin', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="spinToWin"
                                        checked={validation.values.spinToWin ?? false}
                                        readOnly

                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Spin To Win Ads
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>



                    {/* <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label className="form-label">Splash Interval</Label>
                                <Input
                                    name="splashInterval"
                                    type="text"
                                    placeholder="Splash Interval"
                                    validate={{
                                        required: { value: false },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.splashInterval || ""}

                                />

                            </div>
                        </Col>
                        <Col md={6} className="align-self-center mt-3">
                            <div>
                                <div onClick={() => {
                                    const newCheckboxValue = !validation.values.splash;
                                    validation.setFieldValue('splash', newCheckboxValue);
                                }} className="form-check">
                                    <Input
                                        type="checkbox"
                                        name="splash"
                                        checked={validation.values.splash ?? false}
                                        readOnly
                                    />
                                    <Label
                                        className="form-check-Label"
                                        htmlFor="formrow-customCheck"
                                    >
                                        Show Splash Ads
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <div>
                        <button disabled={isUpdateLoading} type="submit" className="btn btn-primary w-md">
                            Submit
                        </button>
                    </div>

                </Form>
            </Container>




        </React.Fragment>
    );
}

UnityAdsSettings.propTypes = {
    settings: PropTypes.any,
    mainSettings: PropTypes.any
}

export default UnityAdsSettings;