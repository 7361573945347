import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as moment from "moment";

const UserId = (cell) => {
    return <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const UserName = (cell) => {
    return cell.value ? cell.value : "";
};

const Email = (cell) => {
    return cell.value ? cell.value : "";
};

const CountryCode = (cell) => {
    return cell.value ? cell.value : "";
};

const MobileNumber = (cell) => {
    return cell.value ? cell.value : "";
};

const RefferedCode = (cell) => {
    return cell.value ? cell.value : "";
};

const RefferedBy = (cell) => {
    return cell.value ? cell.value : "";
};

const Points = (cell) => {
    return cell.value ? cell.value : "";
};

const Type = (cell) => {
    switch (cell.value) {
        case "Full Time":
            return <Badge className="badge-soft-success">Full Time</Badge>
        case "Part Time":
            return <Badge className="badge-soft-danger">Part Time</Badge>
        case "Freelance":
            return <Badge className="badge-soft-info">Freelance</Badge>
        case "Internship":
            return <Badge className="badge-soft-warning">Internship</Badge>
    };   
};

const PostedDate = (cell) => {
    return cell.value ? cell.value : "";
};

const LastDate = (cell) => {
    return cell.value ? formateDate(cell.value,'DD/MM/Y hh:mm a') : "";
};

const Status = (cell) => {
    switch(cell.value) {
        case 1:
            return <Badge className="bg-success">Active</Badge>
        case 0:
            return <Badge className="bg-danger">Deactive</Badge>
    }
};

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};


export { UserName, UserId, Email, CountryCode, MobileNumber, RefferedCode, RefferedBy,Points,Type,LastDate, Status };