import {
    GET_CATEGORY_LIST_FAIL,
    GET_CATEGORY_LIST_SUCCESS,
    ADD_CATEGORY_LIST_SUCCESS,
    ADD_CATEGORY_LIST_FAIL,
    UPDATE_CATEGORY_LIST_SUCCESS,
    UPDATE_CATEGORY_LIST_FAIL,
    DELETE_CATEGORY_LIST_SUCCESS,
    DELETE_CATEGORY_LIST_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    categories: [],
    error: "",
    jobApply: [],
}

const CategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CATEGORY_LIST_SUCCESS:

            return {
                ...state,
                categories: action.payload,
            };

        case GET_CATEGORY_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_CATEGORY_LIST_SUCCESS:
            debugger
            return {

                ...state,
                categories: state.categories.map(category =>
                    category.id.toString() === action.payload.id.toString()
                        ? { category, ...action.payload }
                        : category
                ),
            };

        case UPDATE_CATEGORY_LIST_FAIL:
            debugger
            return {
                ...state,
                error: action.payload,
            };

        case ADD_CATEGORY_LIST_SUCCESS:
            debugger
            return {
                ...state,
                categories: [...state.categories, action.payload],
            };

        case ADD_CATEGORY_LIST_FAIL:
            debugger
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_CATEGORY_LIST_SUCCESS:
            debugger
            return {
                categories: state.categories.filter(
                    category => category.id.toString() !== action.payload.toString()
                ),
            };
        case DELETE_CATEGORY_LIST_FAIL:
            debugger
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state
    }
}

export default CategoryReducer;