import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "components/Common/withRouter";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    Button,
    Alert
} from "reactstrap";

import {
    sendNotification
} from "store/actions";


function Notification() {

    //meta title
    document.title = "Notification | SCRATCH IT & WIN IT";

    const dispatch = useDispatch();

    const { error, success } = useSelector(state => ({
        error: state.Notification.error,
        success: state.Notification.success,
    }));

    useEffect(() => {
        if(success !== undefined && success !== ""){
            validation.resetForm();
        }
    }, [dispatch, success]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            title:'',
            body: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter title"),
            body: Yup.string().required("Please enter body"),
        }),
        onSubmit: (values) => {
            dispatch(sendNotification(values));
            
        }
    });

    return ( 
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Notification" breadcrumbItem="Notification" />

                    {error && error ? <Alert color="danger">{error}</Alert> : null}
                            {success ? <Alert color="success">{success}</Alert> : null}

                    <Card>
                        <CardBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="form-group">
                                    <Label className="form-label">Title</Label>
                                    <Input
                                        name="title"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter title"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.title || ""}
                                        invalid={
                                            validation.touched.title && validation.errors.title ? true : false
                                        }
                                    />
                                    {validation.touched.title && validation.errors.title ? (
                                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                    ) : null}

                                    <Label className="form-label mt-3">Body</Label>
                                    <Input
                                        name="body"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter body"
                                        type="textarea"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.body || ""}
                                        invalid={
                                            validation.touched.body && validation.errors.body ? true : false
                                        }
                                    />
                                    {validation.touched.body && validation.errors.body ? (
                                        <FormFeedback type="invalid">{validation.errors.body}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="text-center mt-4">
                                    <Button type="submit" color="danger">
                                        Send Notification
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
}


export default withRouter(Notification);