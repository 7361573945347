import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_DASHBOARD_DATA } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

import {
    getDashboardData
} from "helpers/backend_helper";

function* fetchDashboardData() {
    try {
        const response = yield call(getDashboardData)
        if(response.status){ 
            yield put(apiSuccess(GET_DASHBOARD_DATA,response.data))
        }else{
            yield put(apiFail(GET_DASHBOARD_DATA,response.message))
        }
    } catch (error) {
        yield put(apiFail(GET_DASHBOARD_DATA,error))
    }
}

function* dashboardSaga() {
    yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
}

export default dashboardSaga;
