import {
  GET_USER_LIST,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL
} from "./actionTypes";

export const getUserList = () => ({
  type: GET_USER_LIST,
})

export const getUserListSuccess = users => ({
  type: GET_USER_LIST_SUCCESS,
  payload: users,
})

export const getUserListFail = error => ({
  type: GET_USER_LIST_FAIL,
  payload: error,
})


export const getUserDetail = userId => ({
  type: GET_USER_DETAIL,
  userId,
})

export const getUserDetailSuccess = userDetails => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: userDetails,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})


export const deleteUser = data => ({
  type: DELETE_USER,
  payload: data,
})

export const deleteUserSuccess = userId => ({
  type: DELETE_USER_SUCCESS,
  payload: userId,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const editUser = data => ({
  type: EDIT_USER,
  payload: data,
})

export const editUserSuccess = data => ({
  type: EDIT_USER_SUCCESS,
  payload: data,
})

export const editUserFail = error => ({
  type: EDIT_USER_FAIL,
  payload: error,
})



