import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";

import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';



//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import withRouter from "components/Common/withRouter";


import {
    UserId, UserName, Email, CountryCode, MobileNumber, RefferedCode, RefferedBy, Type, PostedDate, LastDate, Status
}
    from "./UserListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import classnames from "classnames";

import {
    Col,
    TabContent,
    TabPane,
    Card,
    CardBody,
    NavLink,
    NavItem, Nav,
    Spinner,
    
} from "reactstrap";

import CardUser from "./card-user"
import DailyCoins from "./daily-coins"
import GameTransactions from "./game-transaction"
import WithdrawalTransactions from "./withdrawal-transaction"

import {
    getUserDetail as onGetUserDetail,
} from "store/actions";

const UserDetails = props => {

    //meta title
    document.title = "Users List | SCRATCH IT & WIN IT";

    const [userDetail, setUserDetail] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [activeTab, setActiveTab] = useState("1");
    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const [transactionActiveTab, setTransactionActiveTab] = useState("1");
    const transactionToggleTab = tab => {
        if (transactionActiveTab !== tab) {
            setTransactionActiveTab(tab);
        }
    };


    const params = props.router.params;


    const dispatch = useDispatch();

    const { userDetails } = useSelector(state => ({
        userDetails: state.UsersReducer.userDetails,
    }));

    useEffect(() => {
        if (params && params.id && isEmpty(userDetail)) {

            dispatch(onGetUserDetail(params.id));
        }
    }, [(dispatch)]);


    useEffect(() => {
        if (!isEmpty(userDetails)) {
            setUserDetail(userDetails);
            setIsLoading(false);
        }
    }, [userDetails]);


    return (

        <React.Fragment>

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Users" breadcrumbItem="User Details" />
                    {isLoading ? <div style={{ textAlign: 'center', flex: 1, alignItems: 'center', }}>
                        <Spinner className="ms-2" color="primary" />
                    </div> : <div>
                        <CardUser userInfo={userDetail.user_info} totalWithdrawCoins={userDetail.total_withdraw_coins} />
                        <Col lg="12">
                            <Card>

                                <CardBody>
                                    <h4 className="card-title mb-4">Daily/Total Coins</h4>
                                    <div className="crypto-buy-sell-nav">
                                        <Nav tabs className="nav-tabs-custom" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("1");
                                                    }}
                                                >
                                                    Daily
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("2");
                                                    }}
                                                >
                                                    Total
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <div className="mt-4">
                                            {activeTab === "1" ? <DailyCoins dailyCoins={userDetail.daily_coins} title="Daily Coins" /> : <DailyCoins dailyCoins={userDetail.total_coins} title="Total Coins" />}
                                        </div>
                                    </div>
                                </CardBody></Card>
                        </Col>

                        <Col lg="12">
                            <Card>

                                <CardBody>
                                    <h4 className="card-title mb-4">Game/Withdrawal Transactions</h4>
                                    <div className="crypto-buy-sell-nav">
                                        <Nav tabs className="nav-tabs-custom" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: transactionActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        transactionToggleTab("1");
                                                    }}
                                                >
                                                    Game
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: transactionActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        transactionToggleTab("2");
                                                    }}
                                                >
                                                    Withdrawal
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <div className="mt-4">
                                            {transactionActiveTab === "1" ? <GameTransactions transactions={userDetail.transactions}/> : <WithdrawalTransactions withdrawalTransactions={userDetail.withdrawal_request} title="Total Coins" />}
                                        </div>
                                    </div>
                                </CardBody></Card>
                        </Col>


                    </div>}
                </div>
            </div>
        </React.Fragment>
    );
}


export default withRouter(UserDetails);