import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import QRCode from "react-qr-code";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal'

import GoogleAdsSettings from '../AdsSettings/google-ads-settings';
import UnityAdsSettings from '../AdsSettings/unity-ads-settings';


import {
    WithdrawalId, WithdrawalName, PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount, Points, PostedDate, LastDate, Status
}
    from "./app-update-settings-col";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Form,
    Input,
    Label,
    Card,
    CardBody,
    Container,
    CardTitle,
    Spinner

} from "reactstrap";

import {
    getSettings as onGetSettings,
    upateSettings as onUpateSettings
} from "store/actions";
import AdColonyAdsSettings from "./ad-colony-ads-settings";

function AdsSettings() {

    //meta title
    document.title = "Ads Settings | SCRATCH IT & WIN IT";

    const [adsType, setAdsType] = useState("");

    const [appUpdateSettings, setAppUpdateSettings] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    var initialValues = {};

    const dispatch = useDispatch();
    const { settings, isUpdateLoading } = useSelector(state => {
        return {
            settings: state.SettingsReducer.settings,
            isUpdateLoading: state.SettingsReducer.isUpdateLoading
        };
    });

    useEffect(() => {
        if (isEmpty(appUpdateSettings)) {
            dispatch(onGetSettings());
        }
    }, [dispatch]);


    useEffect(() => {
        if (!isEmpty(settings)) {
            if (settings.ad.multiple_ad_support.google_ad === true) {
                debugger
                setAdsType("google_ad");
            }
            if (settings.ad.multiple_ad_support.unity_ad === true) {
                debugger
                setAdsType("unity_ad");
            }
            if (settings.ad.multiple_ad_support.ad_colony === true) {
                debugger
                setAdsType("ad_colony");
            }
            setAppUpdateSettings(settings);
            setIsLoading(false);
        }
    }, [settings]);






    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Settings" breadcrumbItem="Ads" />
                    {isLoading ? <div style={{ textAlign: 'center', flex: 1, alignItems: 'center', }}>
                        <Spinner className="ms-2" color="primary" />
                    </div> : <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Ads Settings</CardTitle>

                                    <Row className="mb-4">
                                        <Col md={2}>
                                            <Label className="form-label">Ads Type : </Label>
                                        </Col>
                                        <Col md={2}>
                                            <div onClick={() => {

                                                setAdsType("google_ad");
                                            }} className="form-check mb-2" >
                                                <input
                                                    type="radio"
                                                    value="google_ad"
                                                    className="form-check-input"
                                                    checked={adsType === "google_ad"}
                                                />
                                                <Label
                                                    className="form-check-label"

                                                >
                                                    Google Ads
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md={2}>
                                            <div onClick={() => {
                                                setAdsType("unity_ad");
                                            }} className="form-check mb-2">
                                                <input
                                                    type="radio"
                                                    checked={adsType === "unity_ad"}

                                                    className="form-check-input"
                                                    value="unity_ad"
                                                />
                                                <Label
                                                    className="form-check-label"

                                                >
                                                    Unity Ads
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div onClick={() => {
                                                setAdsType("ad_colony");
                                            }} className="form-check mb-2">
                                                <input
                                                    type="radio"
                                                    checked={adsType === "ad_colony"}

                                                    className="form-check-input"
                                                    value="ad_colony"

                                                />
                                                <Label
                                                    className="form-check-label"

                                                >
                                                    Ad Colony
                                                </Label>
                                            </div>
                                        </Col>


                                    </Row>

                                    {(() => {
                                        if (adsType === "google_ad") {
                                            return <GoogleAdsSettings settings={settings.ad.multiple_ad_support.google_ad_settings} mainSettings={settings.ad} />
                                        }
                                        if (adsType === "unity_ad") {
                                            return <UnityAdsSettings settings={settings.ad.multiple_ad_support.unity_ad_settings} mainSettings={settings.ad} />
                                        }
                                        if (adsType === "ad_colony") {
                                            return <AdColonyAdsSettings settings={settings.ad.multiple_ad_support.ad_colony_settings} mainSettings={settings.ad} />
                                        }
                                    })()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}
                </Container>
            </div>
        </React.Fragment>
    );
}




export default AdsSettings;