import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"

import PropTypes from 'prop-types'

function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu


  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="2" className="align-self-center">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={props.userInfo.profile}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="mb-1">{props.userInfo.name}</h5>
                        <p className="mb-0">{props.userInfo.country_code} {props.userInfo.phone}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="10" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                    <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Email
                          </p>
                          <h5 className="mb-2">{props.userInfo.email || "-"}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          Reffered Code
                          </p>
                          <h5 className="mb-2">{props.userInfo.refferedCode || "-"}</h5>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          Reffered By
                          </p>
                          <h5 className="mb-2">{props.userInfo.refferedBy || "-"}</h5>
                        </div>
                      </Col>
                       <Col xs="3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          Total Withdraw Coins
                          </p>
                          <h5 className="mb-0">{props.totalWithdrawCoins}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}


CardUser.propTypes = {
  userInfo: PropTypes.any,
  totalWithdrawCoins:PropTypes.any
}

export default CardUser
