export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_ADS_SETTINGS_FAIL"
export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS"
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL"









