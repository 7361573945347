import { call, put, takeEvery } from "redux-saga/effects"

import { GET_USER_LIST,GET_USER_DETAIL,DELETE_USER,EDIT_USER } from "./actionTypes";
import {
    getUserListSuccess,
    getUserListFail,
    getUserDetailSuccess,
    deleteUserSuccess,
    deleteUserFail,
    editUserSuccess,
    editUserFail
} from "./actions"

import {
    getUserList,
    getUserDetails,
    deleteUser,
    editUser
} from "helpers/backend_helper";

import {
  showToast,
  clearToast
} from "helpers/toastr_helper";

function* fetchUserList() {
    try {
        const response = yield call(getUserList)
       
        if(response.status){
          
            yield put(getUserListSuccess(response.data))
        }else{
            yield put(getUserListFail(response.message))
        }
        
    } catch (error) {
        yield put(getUserListFail(error))
    }
}

function* fetchUserDetail({ userId }) {
    try {
      const response = yield call(getUserDetails, {"user_id":userId})
      if(response.status){
        yield put(getUserDetailSuccess(response.data))
      }else{
        yield put(getUserDetailFail(response.message))
      }
    } catch (error) {
      yield put(getUserDetailFail(error))
    }
  }

  function* deleteSelectedUser({ payload: data }) {
    try {
      const response = yield call(deleteUser, data)
      if(response.status){
        showToast("Success",response.message),
        yield put(deleteUserSuccess(data.id))
      }else{
        yield put(deleteUserFail(response.message))
      }
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }

  function* editSelectedUser({ payload: data }) {
    try {
      const response = yield call(editUser, data)
      debugger
      if(response.status){
        debugger
        yield put(editUserSuccess(response.data))
      }else{
        yield put(editUserFail(response.message))
      }
    } catch (error) {
      yield put(editUserFail(error))
    }
  }

function* usersSaga() {
    yield takeEvery(GET_USER_LIST, fetchUserList)
    yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
    yield takeEvery(DELETE_USER, deleteSelectedUser)
    yield takeEvery(EDIT_USER, editSelectedUser)
}

export default usersSaga;