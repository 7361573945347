import { all, fork } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import dashboardSaga from "./dashboard/saga";
import AuthSaga from "./auth/login/saga";
import usersSaga from "./Users/saga";
import withdrawalSaga from "./Withdrawal/saga";
import categorySaga from "./Category/saga";
import settingsSaga from "./settings/saga";
import ProfileSaga from "./profile/saga";
import NotificationSaga from "./notification/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(usersSaga),
    fork(withdrawalSaga),
    fork(categorySaga),
    fork(settingsSaga),
    fork(ProfileSaga),
    fork(NotificationSaga),
  ]);
}
