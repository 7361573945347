import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import QRCode from "react-qr-code";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';


import {
    WithdrawalId, WithdrawalName, PaymentMethod, PaymentTxnId, PaymentInfo, TransactionFee, Amount, Points, PostedDate, LastDate, Status
}
    from "./WithdrawalListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap";

import {
    getWithdrawalList as onGetWithdrawalList,
    updateWithdrawalList as onUpdateWithdrawalList,
} from "store/actions";

function Withdrawal() {

    //meta title
    document.title = "Withdrawal List | SCRATCH IT & WIN IT";

    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [withdrawalList, setWithdrawalList] = useState([]);
    const [withdrawalInfo, setWithdrawalInfo] = useState(null);

    const dispatch = useDispatch();
    const { withdrawal } = useSelector(state => ({
        withdrawal: state.WithdrawalReducer.withdrawal,
    }));

    useEffect(() => {
        if (withdrawal && !withdrawal.length) {
            dispatch(onGetWithdrawalList());
        }
    }, [dispatch, withdrawal]);

    useEffect(() => {
        setWithdrawalList(withdrawal);
    }, [withdrawal]);

    useEffect(() => {
        if (!isEmpty(withdrawal) && !!isEdit) {
            setWithdrawalList(withdrawal);
            setIsEdit(false);
        }
    }, [withdrawal]);

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'id',
                filterable: true,
                Cell: (cellProps) => {
                    return <WithdrawalId {...cellProps} />;
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                filterable: true,
                Cell: (cellProps) => {
                    return <WithdrawalName {...cellProps} />;
                }
            },
            {
                Header: 'Payment Method',
                accessor: 'payment_method_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <PaymentMethod {...cellProps} />;
                }
            },
            {
                Header: 'Payment txnid',
                accessor: 'payment_gateway_txnid',
                filterable: true,
                Cell: (cellProps) => {
                    return <PaymentTxnId {...cellProps} />;
                }
            },
            {
                Header: 'Payment Info',
                accessor: 'ref',
                filterable: true,
                Cell: (cellProps) => {
                    return <PaymentInfo {...cellProps} />;
                }
            },
            {
                Header: 'Transaction Fee',
                accessor: 'transaction_fee',
                Cell: (cellProps) => {
                    return <TransactionFee {...cellProps} />;
                }
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: (cellProps) => {
                    return <Amount {...cellProps} />;
                }
            },
            {
                Header: 'points',
                accessor: 'point',
                Cell: (cellProps) => {
                    return <Points {...cellProps} />;
                }
            },
            {
                Header: 'Last Date',
                accessor: 'createdAt',
                Cell: (cellProps) => {
                    return <LastDate {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,

                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#!" className="btn btn-sm btn-soft-primary" onClick={() => {
                                    setModal(true)
                                    const withdrawalData = cellProps.row.original;
                                    setWithdrawalInfo(withdrawalData);
                                }}>
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            {(() => {

                                if (cellProps.row.values.status === "pending") {
                                    return <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-info"
                                            onClick={() => {
                                                setEditModal(true)
                                                const withdrawalData = cellProps.row.original;
                                                setWithdrawalInfo(withdrawalData);
                                            }}
                                        >
                                            <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                            <UncontrolledTooltip placement="top" target="edittooltip">
                                                Edit
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                }
                            })()}



                        </ul>
                    );
                }
            },
        ],
        []
    );

    const toggle = () => {
        if (modal) {
            setModal(false);
            // setJob(null);
            setWithdrawalInfo(null);
        } else {
            setModal(true);
        }
    };

    const editToggle = () => {
        if (editModal) {
            setEditModal(false);
            // setJob(null);
            setWithdrawalInfo(null);
        } else {
            setEditModal(true);
        }
    };

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            paymentTxnid: (withdrawalInfo && withdrawalInfo.payment_gateway_txnid) || '',
            payoutFrom:  (withdrawalInfo && (JSON.parse(withdrawalInfo.payment_gateway_info) && JSON.parse(withdrawalInfo.payment_gateway_info).payout_app) || '') || '',
            status: (withdrawalInfo && withdrawalInfo.status) || '',
        },
        validationSchema: Yup.object({
            paymentTxnid: Yup.string().required("Please Enter Payment Txn id"),
            payoutFrom: Yup.string().required("Please Enter Payout Form"),
            status: Yup.string().required("Please Enter Your Status"),
        }),
        validate: (values) => {
            let errors = {};
            if (values.status === 'pending') {
                errors.status = "If you want to edit withdrawal request then you select another value for example select the success, cancel or failed."
            }
            return errors;
        },
        onSubmit: (values) => {
            const updateWithdrawal = {
                id: withdrawalInfo ? withdrawalInfo.id : 0,
                payment_gateway_txnid: values.paymentTxnid,
                payment_gateway_info: values.payoutFrom,
                status: values.status,
            };
            // update Withdrawal
            dispatch(onUpdateWithdrawalList(updateWithdrawal));
            validation.resetForm();
            editToggle();
        },
    });


    return (
        <React.Fragment>
            {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Withdrawal" breadcrumbItem="Withdrawal" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Withdrawal Lists</h5>
                                        {/* <div className="flex-shrink-0">
                                            <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Add New Job</Link>
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                            <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div> */}
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={withdrawal}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        // handleJobClicks={handleJobClicks}
                                        isJobListGlobalFilter={true}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
                        <ModalHeader toggle={toggle} tag="h4">
                            View Withdrawal Info
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col className="col-3">
                                    <h5 className="text-truncate font-size-14">Name:</h5>
                                </Col>
                                <Col className="col-9">
                                    <p className="text-muted mb-0 text-end"> {withdrawalInfo ? withdrawalInfo.name : ""}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <h5 className="text-truncate font-size-14">Payment ID:</h5>
                                </Col>
                                <Col className="col-9" >
                                    <p className="text-muted mb-0 text-end">{withdrawalInfo ? JSON.parse(withdrawalInfo.ref) ? JSON.parse(withdrawalInfo.ref).payment_id : "" : ""}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <h5 className="text-truncate font-size-14">Amount:</h5>
                                </Col>
                                <Col className="col-9">
                                    <p className="text-muted mb-0 text-end">  {(() => {
                                        if (withdrawalInfo) {
                                            var exchangeRate = JSON.parse(withdrawalInfo.ref).exchange_rate ? JSON.parse(withdrawalInfo.ref).exchange_rate : 0.0;
                                            return "₹" + roundToTwo((((withdrawalInfo.point * exchangeRate))));
                                        }
                                        return "";
                                        // withdrawalInfo ? withdrawalInfo.amount : ""
                                    })()}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-4">
                                    <h5 className="text-truncate font-size-14">Transaction Fee:</h5>
                                </Col>
                                <Col className="col-8" >
                                    <p className="text-muted mb-0 text-end">₹{withdrawalInfo ? JSON.parse(withdrawalInfo.ref) ? JSON.parse(withdrawalInfo.ref).process_fee ? (JSON.parse(withdrawalInfo.ref).process_fee) : "0" : "0" : "0"}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-4">
                                    <h5 className="text-truncate font-size-14">Received Amount:</h5>
                                </Col>
                                <Col className="col-8">
                                    <p className="text-muted mb-0 text-end">  {(() => {
                                        if (withdrawalInfo) {
                                            var exchangeRate = JSON.parse(withdrawalInfo.ref).exchange_rate ? JSON.parse(withdrawalInfo.ref).exchange_rate : 0.0;
                                            var processFee = JSON.parse(withdrawalInfo.ref).process_fee ? JSON.parse(withdrawalInfo.ref).process_fee : 0.0;
                                            return "₹" + roundToTwo((((withdrawalInfo.point * exchangeRate) - processFee)));
                                        }
                                        return "";
                                        // withdrawalInfo ? withdrawalInfo.amount : ""
                                    })()}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <h5 className="text-truncate font-size-14">Ref:</h5>
                                </Col>
                                <Col className="col-9" >
                                    <p className="text-muted mb-0" style={{ lineBreak: "anywhere", textAlign: "justify" }}>  {withdrawalInfo ? withdrawalInfo.ref : ""}</p>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={editModal} toggle={editToggle} className="modal-dialog-centered">
                        <ModalHeader toggle={editToggle} tag="h4">
                            Edit Payment Info
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="align-items-center">
                                    <Col className="col-3 ">
                                        
                                        <QRCode
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={(() => {
                                                if(withdrawalInfo){
                                                    var exchangeRate = JSON.parse(withdrawalInfo.ref).exchange_rate ? JSON.parse(withdrawalInfo.ref).exchange_rate : 0.0;
                                                    var processFee = JSON.parse(withdrawalInfo.ref).process_fee ? JSON.parse(withdrawalInfo.ref).process_fee : 0.0;
                                                    
                                                    var upiID = withdrawalInfo ? JSON.parse(withdrawalInfo.ref) ? JSON.parse(withdrawalInfo.ref).payment_id : "" : "";
                                                    var payeeName = withdrawalInfo ? withdrawalInfo.name : "";
                                                    var transactionID = "";
                                                    var amount = roundToTwo((((withdrawalInfo.point * exchangeRate) - processFee)));
                                                    var currencyCode = "INR";
                                                    var transactionNote = "SCRATCH IT AND WIN IT";
                                                    
                                                    return `upi://pay?pa=${upiID}&pn=${payeeName}&tr=${transactionID}&am=${amount}&cu=${currencyCode}&mode=01&purpose=10&orgid=-&sign=-&tn=${transactionNote}`;
                                                }
                                                return "";
                                               
                                                // withdrawalInfo ? withdrawalInfo.amount : ""
                                            })()}
                                            viewBox={`0 0 200 200`}
                                        />
                                    </Col>
                                    <Col className="col-9" >
                                        <Row>
                                            <Col className="col-6">
                                                <h5 className="text-truncate font-size-14">Payment ID:</h5>
                                            </Col>
                                            <Col className="col-6" >
                                                <p className="text-muted mb-0 text-end">{withdrawalInfo ? JSON.parse(withdrawalInfo.ref) ? JSON.parse(withdrawalInfo.ref).payment_id : "" : ""}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <h5 className="text-truncate font-size-14">Name:</h5>
                                            </Col>
                                            <Col className="col-6" >
                                                <p className="text-muted mb-0 text-end">{withdrawalInfo ? withdrawalInfo.name : ""}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <h5 className="text-truncate font-size-14">Amount:</h5>
                                            </Col>
                                            <Col className="col-6">
                                                <p className="text-muted mb-0 text-end">  {(() => {
                                                    if (withdrawalInfo) {
                                                        var exchangeRate = JSON.parse(withdrawalInfo.ref).exchange_rate ? JSON.parse(withdrawalInfo.ref).exchange_rate : 0.0;
                                                        return "₹" + roundToTwo((((withdrawalInfo.point * exchangeRate))));
                                                    }
                                                    return "";
                                                    // withdrawalInfo ? withdrawalInfo.amount : ""
                                                })()}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <h5 className="text-truncate font-size-14">Transaction Fee:</h5>
                                            </Col>
                                            <Col className="col-6" >
                                                <p className="text-muted mb-0 text-end">₹{withdrawalInfo ? JSON.parse(withdrawalInfo.ref) ? JSON.parse(withdrawalInfo.ref).process_fee ? (JSON.parse(withdrawalInfo.ref).process_fee) : "0" : "0" : "0"}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <h5 className="text-truncate font-size-14">Received Amount:</h5>
                                            </Col>
                                            <Col className="col-6">
                                                <p className="text-muted mb-0 text-end">  {(() => {
                                                    if (withdrawalInfo) {
                                                        var exchangeRate = JSON.parse(withdrawalInfo.ref).exchange_rate ? JSON.parse(withdrawalInfo.ref).exchange_rate : 0.0;
                                                        var processFee = JSON.parse(withdrawalInfo.ref).process_fee ? JSON.parse(withdrawalInfo.ref).process_fee : 0.0;
                                                        return "₹" + roundToTwo((((withdrawalInfo.point * exchangeRate) - processFee)));
                                                    }
                                                    return "";
                                                    // withdrawalInfo ? withdrawalInfo.amount : ""
                                                })()}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="col-12 mb-3 mt-3" style={{height:"1px", backgroundColor:"#ced4da", width:"100%"}}></div>
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">Payment Txnid</Label>
                                            <Input
                                                name="paymentTxnid"
                                                type="text"
                                                placeholder="Payment txnid"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.paymentTxnid || ""}
                                                invalid={
                                                    validation.touched.paymentTxnid && validation.errors.paymentTxnid ? true : false
                                                }
                                            />
                                            {validation.touched.paymentTxnid && validation.errors.paymentTxnid ? (
                                                <FormFeedback type="invalid">{validation.errors.paymentTxnid}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Payout From</Label>
                                            <Input
                                                name="payoutFrom"
                                                type="text"
                                                placeholder="Payout From"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.payoutFrom || ""}
                                                invalid={
                                                    validation.touched.payoutFrom && validation.errors.payoutFrom ? true : false
                                                }
                                            />
                                            {validation.touched.payoutFrom && validation.errors.payoutFrom ? (
                                                <FormFeedback type="invalid">{validation.errors.payoutFrom}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>pending</option>
                                                <option>success</option>
                                                <option>cancel</option>
                                                <option>failed</option>
                                            </Input>
                                            {(() => {
                                                
                                                if (validation.touched.status && validation.errors.status) {
                                                    return <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                                }
                                                return null
                                            })()}

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                </div>
            </div>
        </React.Fragment>
    );
}


function roundToTwo(num) {
    return +(Math.ceil(num + "e+3") + "e-3");
}


export default Withdrawal;