import { call, put, takeEvery } from "redux-saga/effects"

import { GET_WITHDRAWAL_LIST,UPDATE_WITHDRAWAL_LIST } from "./actionTypes";
import {
    getWithdrawalListSuccess,
    getWithdrawalListFail,
    updateWithdrawalListFail,
    updateWithdrawalListSuccess,
    

} from "./actions"

import {
    getWithdrawalList,
    updateWithdrawalist
} from "helpers/backend_helper";

function* fetchWithdrawalList() {
    try {
        const response = yield call(getWithdrawalList)
        if(response.status){ 
            yield put(getWithdrawalListSuccess(response.data))
        }else{
            yield put(getWithdrawalListFail(response.message))
        }
    } catch (error) {
        yield put(getWithdrawalListFail(error))
    }
}

function* onUpdateWithdrawalList({ payload: data }) {
    try {
        const response = yield call(updateWithdrawalist, data)
        if(response.status){ 
            yield put(updateWithdrawalListSuccess(response.data))
        }else{
            yield put(updateWithdrawalListFail(response.message))
        }
    } catch (error) {
        yield put(updateWithdrawalListFail(error))
    }
}

function* withdrawalSaga() {
    yield takeEvery(GET_WITHDRAWAL_LIST, fetchWithdrawalList)
    yield takeEvery(UPDATE_WITHDRAWAL_LIST, onUpdateWithdrawalList)
}

export default withdrawalSaga;