import React, { useState } from "react"

//Import Images
import avatar1 from "../../../assets/images/users/avatar-1.jpg"
import "../UserDetails/userDetails.scss"

import TableContainer from '../../../components/Common/TableContainer';

import PropTypes from 'prop-types'

import { useMemo } from "react";


import { Idno, CommonCell, IsCreditCell, Status, LastDate } from "./game-transaction-col";


function GameTransactions(props) {


    const [settingsMenu, setSettingsMenu] = useState(false)


    const columns = useMemo(
        () => [
          {
            Header: "Id",
            accessor: "id",
            filterable: true,
            Cell: cellProps => {
              return <Idno {...cellProps} />;
            },
          },
          {
            Header: "Points",
            accessor: "point",
            filterable: true,
            Cell: cellProps => {
              return <CommonCell {...cellProps} />;
            },
          },
          {
            Header: "Reason",
            accessor: "reason",
            filterable: true,
            Cell: cellProps => {
              return <CommonCell {...cellProps} />;
            },
          },
          {
            Header: "Is Credit",
            accessor: "is_credit",
            filterable: true,
            Cell: cellProps => {
              return <IsCreditCell {...cellProps} />;
            },
          },
          {
            Header: 'Date',
            accessor: 'updatedAt',
            Cell: (cellProps) => {
              return <LastDate {...cellProps} />;
            }
          },
          {
            Header: "Status",
            accessor: "status",
            filterable: false,
            disableFilters: true,
            Cell: cellProps => {
              return <Status {...cellProps} />;
            },
          },
        ],
        []
      );

    return (
        <React.Fragment>
             <TableContainer
            columns={columns}
            data={props.transactions}
            isGlobalFilter={true}
            customPageSize={10}
          />
        </React.Fragment>
    )
}

GameTransactions.propTypes = {
    transactions: PropTypes.any
}

export default GameTransactions
