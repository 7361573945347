import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const getUserList = () => get(url.GET_USERS);
export const getUserDetails = data => post(url.GET_USER_DETAIL, data);
export const deleteUser = data => post(url.DELETE_USER, data);
export const editUser = data => post(url.EDIT_USER, data);


//Auth
const postLogin = data => post(url.POST_LOGIN,data);

//Withdrawal
const getWithdrawalList = () => get(url.GET_WITHDRAWAL_REQUEST);

// update Withdrawal List
const updateWithdrawalist = data => post(url.UPDATE_WITHDRAWAL_LIST, data);

//Settings
const getSettings = () => get(url.GET_SETTINGS);
const commonUpdateSettings = data => post(url.UPDATE_SETTINGS, data);

//Category
const getCategoryList = () => get(url.GET_CATEGORY_REQUEST);
const updateCategoryist = data => post(url.UPDATE_CATEGORY_LIST, data);
const addCategoryist = data => post(url.ADD_CATEGORY_LIST, data);
const deleteCategoryList = data => post(url.DELETE_CATEGORY_LIST, data);

//Dashboard
const getDashboardData = () => get(url.GET_DASHBOARD_DATA);

//Edit Profile
const editAdminProfile = data => post(url.EDIT_PROFILE, data);

//Send Notification
const postSendNotification = data => post(url.SEND_NOTIFICATION, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  getWithdrawalList,
  updateWithdrawalist,
  getCategoryList,
  updateCategoryist,
  addCategoryist,
  deleteCategoryList,
  getSettings,
  commonUpdateSettings,
  getDashboardData,
  editAdminProfile,
  postSendNotification
};
