import { call, put, takeEvery } from "redux-saga/effects"

import { GET_SETTINGS,UPDATE_SETTINGS } from "./actionTypes";
import {
    getSettingsSuccess,
    getSettingsFail,
    upateSettingsSuccess,
    upateSettingsFail,
    upateSettings

} from "./actions"

import {
    getSettings,commonUpdateSettings

} from "helpers/backend_helper";

import {
    showToast,
    clearToast
} from "helpers/toastr_helper";


function* fetchSettings() {
    try {
        const response = yield call(getSettings)
        if(response.status){ 
            yield put(getSettingsSuccess(response.data))
        }else{
            yield put(getSettingsFail(response.message))
        }
    } catch (error) {
        yield put(getSettingsFail(error))
    }
}

function* updateSettings({ payload: data }) {
    try {
        const response = yield call(commonUpdateSettings,data)
        if(response.status){ 
            const responseGetSettings = yield call(getSettings)
            showToast("Success",response.message)
            yield put(upateSettingsSuccess(responseGetSettings.data))
        }else{
            showToast("Error",response.message,"error"),
            yield put(upateSettingsFail(response.message))
        }
    } catch (error) {
        showToast("Error",error,"error"),
        yield put(upateSettingsFail(error))
    }
}

function* settingsSaga() {
    yield takeEvery(GET_SETTINGS, fetchSettings)
    yield takeEvery(UPDATE_SETTINGS, updateSettings)
}

export default settingsSaga;