import React from 'react';
import * as moment from "moment";
import { Link } from 'react-router-dom';
import { Badge,Label } from 'reactstrap';

const Idno = (cell) => {
    return  <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const CommonCell = (cell) => {
    return  cell.value ? cell.value : ''
};

const IsCreditCell = (cell) => {
    return  cell.value
};

const Status = (cell) => {
    switch(cell.value) {
        case "pending":
            return <Badge className="bg-info">Pending</Badge>
        case "success":
            return <Badge className="bg-success">Success</Badge>
        case "failed":
            return <Badge className="bg-danger">Failed</Badge>
            case "cancel":
                return <Badge className="bg-danger">Failed</Badge>
    }
};

const LastDate = (cell) => {
    return cell.value ? formateDate(cell.value,'DD/MM/Y hh:mm a') : "";
};

export {
    Idno,
    CommonCell,
    IsCreditCell,
    Status,
    LastDate
};

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

