import {
    GET_WITHDRAWAL_LIST_FAIL,
    GET_WITHDRAWAL_LIST_SUCCESS,
    ADD_WITHDRAWAL_LIST_SUCCESS,
    ADD_WITHDRAWAL_LIST_FAIL,
    UPDATE_WITHDRAWAL_LIST_SUCCESS,
    UPDATE_WITHDRAWAL_LIST_FAIL
   
} from "./actionTypes";

const INIT_STATE = {
    withdrawal: [],
    error: {},
    jobApply: [],
}

const WithdrawalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WITHDRAWAL_LIST_SUCCESS:
            return {
                ...state,
                withdrawal: action.payload,
            };

        case GET_WITHDRAWAL_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
            case UPDATE_WITHDRAWAL_LIST_SUCCESS:
                return {
                    ...state,
                    withdrawal: state.withdrawal.map(singleWithdrawal =>
                        singleWithdrawal.id.toString() === action.payload.id.toString()
                            ? { singleWithdrawal, ...action.payload }
                            : singleWithdrawal
                    ),
                };
    
            case UPDATE_WITHDRAWAL_LIST_FAIL:
                return {
                    ...state,
                    error: action.payload,
                };
        default:
            return state
    }
}

export default WithdrawalReducer;