import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_FAIL,
  GET_CATEGORY_LIST_SUCCESS,
  UPDATE_CATEGORY_LIST,
  UPDATE_CATEGORY_LIST_SUCCESS,
  UPDATE_CATEGORY_LIST_FAIL,
  ADD_CATEGORY_LIST,
  ADD_CATEGORY_LIST_SUCCESS,
  ADD_CATEGORY_LIST_FAIL,
  DELETE_CATEGORY_LIST,
  DELETE_CATEGORY_LIST_SUCCESS,
  DELETE_CATEGORY_LIST_FAIL


} from "./actionTypes";

export const getCategoryList = () => ({
  type: GET_CATEGORY_LIST,
})

export const getCategoryListSuccess = categories => ({
  type: GET_CATEGORY_LIST_SUCCESS,
  payload: categories,
})

export const getCategoryListFail = error => ({
  type: GET_CATEGORY_LIST_FAIL,
  payload: error,
})

export const updateCategoryList = categories => ({
  type: UPDATE_CATEGORY_LIST,
  payload: categories,
})

export const updateCategoryListSuccess = categories => ({
  type: UPDATE_CATEGORY_LIST_SUCCESS,
  payload: categories,
})

export const updateCategoryListFail = error => ({
  type: UPDATE_CATEGORY_LIST_FAIL,
  payload: error,
})

export const addCategoryList = categories => ({
  type: ADD_CATEGORY_LIST,
  payload: categories,
})

export const addCategoryListSuccess = categories => ({
  type: ADD_CATEGORY_LIST_SUCCESS,
  payload: categories,
})

export const addCategoryListFail = error => ({
  type: ADD_CATEGORY_LIST_FAIL,
  payload: error,
})

export const deleteCategoryList = categories => ({
  type: DELETE_CATEGORY_LIST,
  payload: categories,
})

export const deleteCategoryListSuccess = categories => ({
  type: DELETE_CATEGORY_LIST_SUCCESS,
  payload: categories,
})

export const deleteCategoryListFail = error => ({
  type: DELETE_CATEGORY_LIST_FAIL,
  payload: error,
})
