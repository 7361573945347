import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import {
  editAdminProfile
} from "helpers/backend_helper";


function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(editAdminProfile, {
      name: user.name,
      email:user.email,
      id: user.id,
      password:user.password
    })
    if(response.status){ 
      var obj = JSON.parse(localStorage.getItem("authUser"));
      obj["name"] = user.name;
      obj["email"] = user.email;
      obj["id"] = user.id;
      // Assign a value to locastorage
      localStorage.removeItem("authUser");
      localStorage.setItem("authUser", JSON.stringify(obj));
      yield put(profileSuccess(response.message))
    }else{
      yield put(profileError(response.message))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
